import { baselineDisaggregation } from 'react_query/query-keys';
import { getBaselineDisaggregation } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';

const useGetBaselineDisaggregation = (logframeId: string) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [baselineDisaggregation, logframeId],
        queryFn: () => getBaselineDisaggregation(logframeId),
        enabled: !!logframeId
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };
};

export default useGetBaselineDisaggregation;
