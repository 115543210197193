import { Card, IconButton, Typography } from '@mui/material';
import React, { memo, CSSProperties, useEffect } from 'react';
import { Handle, Position, NodeProps, Connection, Edge } from 'react-flow-renderer';
import './style.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import UpdateDialog from './UpdateNodeDialogue';

const targetHandleStyle: CSSProperties = { background: '#555' };
const sourceHandleStyleA: CSSProperties = { ...targetHandleStyle, margin: 'auto' };

const onConnect = (params: Connection | Edge) => console.log('handle onConnect', params);

const uid = function () {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const getId = (id: any) => `${id}_${uid()}`;

const ChildNode: React.FC<NodeProps> = (data: any) => {
    const { color, onRemove, onEdit, onElementsAdd, level, width, name, description, parent, isSdgBased } = data.data;
    const [nodeEntry, setNodeEntry] = React.useState<{ [key: string]: any }>({
        level: null,
        parent: '',
        id: '',
        name: '',
        description: '',
        color: '',
        isActivity: false,
        isSdgBased: isSdgBased ? isSdgBased : false,
        form_url: ''
    });
    const [editable, setEditable] = React.useState<boolean>(false);
    const [faqModal, setFaqModal] = React.useState<boolean>(false);

    const { yPos, id } = data;

    const onSubmit = async () => {
        console.log('-----------------------------|| level 1 || -----------------------------------');
        console.log('------> in submit: ', nodeEntry);
        const formattedData: any = {
            parent: nodeEntry.parent,
            level: nodeEntry.level,
            name: nodeEntry.name,
            form_url: nodeEntry.form_url,
            description: nodeEntry.description,
            isActivity: nodeEntry.isActivity,
            isSdgBased: nodeEntry.isSdgBased,
            onEdit: onEdit,
            onRemove: onRemove,
            color: nodeEntry.color,
            onElementsAdd: onElementsAdd,
            width: '150'
        };

        if (editable) {
            onEdit(id, formattedData);
        } else {
            const newNode = {
                id: getId(id),
                type: 'childNode',
                data: formattedData,
                position: { x: 300, y: parseInt(yPos, 10) + 140 }
            };
            onElementsAdd(id, newNode);
        }

        setFaqModal(false);
        setNodeEntry({ ...nodeEntry, level: null, parent: '', id: '', name: '', description: '', isActivity: false, isSdgBased: false });
    };

    const setValue = (event: any) => {
        console.log('name: ', event.target.name, '   value: ', event.target.value);
        setNodeEntry({ ...nodeEntry, [event.target.name]: event.target.value });
    };

    return (
        <>
            <UpdateDialog
                open={faqModal}
                // editable={editable}
                onOkay={onSubmit}
                onClose={(e: any) => {
                    setFaqModal(false);
                    setNodeEntry({
                        ...nodeEntry,
                        id: '',
                        name: '',
                        description: '',
                        isActivity: false,
                        isSdgBased: isSdgBased,
                        color: color
                    });
                }}
                closeText={`Close`}
                headerText={editable ? `Update Node` : `Create Node`}
                nodeInfo={nodeEntry}
                setValue={setValue}
            />
            <Card className={'node-border'} style={{ borderColor: color ? color : '#f1c751', width: width < 150 ? width : 150 }}>
                <Handle type="target" position={Position.Top} style={targetHandleStyle} onConnect={onConnect} />
                <div className={'cardUpdateAction'}>
                    <IconButton
                        aria-label="add to favorites"
                        onClick={(e) => {
                            e.preventDefault();
                            setEditable(true);
                            setNodeEntry({
                                ...nodeEntry,
                                parent: parent,
                                level: level,
                                id: id,
                                name: name,
                                description: description
                            });
                            setFaqModal(true);
                        }}
                    >
                        <EditIcon className={'updateIcon'} />
                    </IconButton>
                    <IconButton
                        aria-label="share"
                        style={{ paddingLeft: 5 }}
                        onClick={(e) => {
                            e.preventDefault();
                            onRemove([data]);
                        }}
                    >
                        <DeleteIcon className={'updateIcon'} />
                    </IconButton>
                </div>
                <div className={'cardBody'}>
                    <Typography variant="caption" color="primary">
                        {name}
                    </Typography>
                    <div className={'bodyDescription'}>
                        <Typography variant="body2" color="secondary" className={'bodyText'}>
                            {description}
                            {/* Lots of talk about goals which is not achieved yet. So let us look forward to achieve our goal */}
                        </Typography>
                    </div>
                    <Typography variant="body2" color="primary" className={'bodyText'}>
                        More...
                    </Typography>
                </div>
                <div className={'cardAddAction'}>
                    <IconButton
                        aria-label="add to favorites"
                        style={{ marginLeft: -2 }}
                        onClick={(e) => {
                            e.preventDefault();
                            setEditable(false);
                            setNodeEntry({ ...nodeEntry, parent: name, level: level + 1, name: '', description: '', isActivity: false });
                            setFaqModal(true);
                        }}
                    >
                        <AddIcon className={'addIcon'} />
                    </IconButton>
                </div>
                <Handle type="source" position={Position.Bottom} style={sourceHandleStyleA} />
            </Card>
        </>
    );
};

export default memo(ChildNode);
