import { useQuery } from '@tanstack/react-query';
import { fiscalYearList } from '../../../react_query/query-keys';
import { fiscalYearListByAgency } from '../../../services/reactQueryservices';

const useGetFiscalYearList = (agency: string) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [fiscalYearList, agency],
        queryFn: () => fiscalYearListByAgency(agency),
        enabled: true && !!agency
    })
    return {
        data,
        isFetching,
        error
    }
}

export default useGetFiscalYearList;