import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Box, Button, Typography } from '@mui/material';
import Wrapper from 'components/Card/Wrapper';
import SelectTable from 'components/Table/SelectTable';
import useSnackbar from 'hooks/useSnackbar';
import { Serializer } from 'jsonapi-serializer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setAllMember } from 'store/itemReducer';
import { TRootState } from 'store/reducer';
import Loader from 'ui-component/Loader';
import Loading from 'ui-component/Loding';
import axiosServices from 'utils/axiosServices';
import { CURRENT_PAGE, PAGE_SIZE } from 'utils/Constants';
import { _deserailize } from 'utils/Deserialize';
import langString from 'utils/langString';
import { addProjectUserURL, projectGetURL, projectPartnerUsersURL } from 'utils/serverUrls';
import { projectInitialValues } from 'views/Projects/Constants';
import { teamNonMemberColumns } from '../Constants';
import ProjectTeamsFilter from './Filter';

export default function ProjectTeamMemberAdd() {
    const [loading, setLoading] = useState(false);
    const [organization, setOrganization] = useState(0);
    const [role, setRole] = useState(0);
    const [name, setName] = useState('');

    const [count, setCount] = useState(0);
    const [page, setPage] = useState(CURRENT_PAGE);
    const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
    const [selectedFields, setSelectedFields] = useState<number[]>([]);
    const [checkAll, setCheckAll] = useState(false);
    const navigate = useNavigate();
    const snackbarMsg = useSnackbar();
    const [project, setProject] = useState<any>(projectInitialValues);
    const {
        account,
        projects: { currentTab },
        item: { allMember }
    } = useSelector((state: TRootState) => state);
    const { id: projectId } = useParams();
    const dispatch = useDispatch();
    const handleSelection = (itemID: number) => {
        const allSelectedFields = [...selectedFields];
        if (allSelectedFields.includes(itemID)) {
            const filteredList = allSelectedFields.filter((item: number) => item !== itemID);
            setSelectedFields(filteredList);
        } else {
            allSelectedFields.push(itemID);
            setSelectedFields(allSelectedFields);
        }
    };

    const changeCheckAll = (value: boolean) => {
        setCheckAll(value);
        console.log('value', value);
        if (value) {
            const allRows = [...allMember!];
            console.log(allRows);
            const allSelectedFields = [...selectedFields];
            allRows.forEach((item: any) => allSelectedFields.push(item.userId));
            console.log(allSelectedFields);
            setSelectedFields(allSelectedFields);
        } else {
            setSelectedFields([]);
        }
        console.log('setSelected', selectedFields);
    };

    const addTeamMember = async () => {
        const payload = {
            projectId,
            projectmembers: selectedFields.map((userId) => ({ userId }))
        };
        const serializedData = new Serializer('projectMember', {
            keyForAttribute: 'camelCase',
            attributes: Object.keys(payload)
        }).serialize(payload);
        try {
            await axiosServices.post(`${addProjectUserURL}`, serializedData);
            snackbarMsg('Successfully Added to Team');
            navigate(`/project/${project.id}?tabIndex=0`);
        } catch (ex: any) {
            const msg = ex.response.data.errors.map((e: any) => e.detail).join(', ');
            snackbarMsg(msg, 'error');
        }
    };

    const init = async () => {
        setLoading(true);
        try {
            await fetchUsers();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        init();
    }, [page, rowsPerPage, organization, role, name]);

    useEffect(() => {
        setRole(0);
    }, [organization]);

    useEffect(() => {
        if (role === 0) {
            setName('');
        }
    }, [role]);

    const fetchUsers = async () => {
        try {
            if (account.user) {
                const usersParam = {
                    projectId,
                    orgId: organization == 0 ? undefined : organization,
                    username: name,
                    roleName: role == 0 ? '' : role,
                    currentPage: page,
                    pageSize: rowsPerPage
                };

                const projectResponse = await axiosServices.get(`${projectGetURL}`, { params: { id: projectId } });
                const partnerOrgUsers = await axiosServices.get(`${projectPartnerUsersURL}`, { params: usersParam });

                setProject(await _deserailize(projectResponse.data));

                dispatch(setAllMember(await _deserailize(partnerOrgUsers.data)));
                setCount(partnerOrgUsers.data.meta.count);
                console.log('hello');
                console.log('partner org users: ', await _deserailize(partnerOrgUsers.data));
            }
        } catch (error) {
            dispatch(setAllMember(null));
        }
    };

    useEffect(() => {
        console.log('Addd\n\n\n\n\n');
        fetchUsers();
    }, [account.user]);

    return (
        <Wrapper title={langString('addteammember')} icon={<GroupAddIcon />} backLink={`/project/${projectId}?tabIndex=${currentTab}`}>
            {loading && <Loader />}
            {projectId && (
                <ProjectTeamsFilter
                    project={project}
                    organization={organization}
                    setOrganization={setOrganization}
                    role={role}
                    setRole={setRole}
                    name={name}
                    setName={setName}
                    filter={() => init()}
                />
            )}

            <Box sx={{ marginTop: 2 }} textAlign="center">
                {/* eslint-disable-next-line no-nested-ternary */}
                {typeof allMember === 'undefined' ? (
                    <Loading />
                ) : allMember === null ? (
                    <Typography sx={{ marginX: 'auto', marginY: 2 }} variant="h3">
                        Data not found
                    </Typography>
                ) : (
                    <SelectTable
                        rows={allMember}
                        columns={teamNonMemberColumns()}
                        count={count}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setPage={(value: number) => setPage(value)}
                        setRowsPerPage={(value: number) => setRowsPerPage(value)}
                        idField="userId"
                        selectedFields={selectedFields}
                        checkAll={checkAll}
                        changeCheckAll={(value) => {
                            changeCheckAll(value);
                        }}
                        checkUpdate={(value) => handleSelection(value)}
                    />
                )}
                <Button variant="contained" color="success" sx={{ margin: 2 }} onClick={() => addTeamMember()}>
                    {langString('addteammember')}
                </Button>
            </Box>
        </Wrapper>
    );
}
