import React from 'react'
import { logFrameReport } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';
import { logFrameReportkey } from '../../../react_query/query-keys';

const useLogframeReport = (project: string) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [logFrameReportkey, project],
        queryFn: () => logFrameReport(project),
        staleTime: 1 * 60 * 1000
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };
}

export default useLogframeReport;