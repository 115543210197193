import React, { createContext, useEffect, useState } from 'react';
import { SidebarContextType } from 'types';
import axiosServices from 'utils/axiosServices';
import { dashboardPublishedListURL } from 'utils/serverUrls';

export const getSidebarData = async () => {
    try {
        const response: any = await axiosServices.get(`${dashboardPublishedListURL}`);
        if (response.status === 200) {
            return response.data;
        }
        return [];
    } catch (ex) {
        return [];
    }
};

// ==============================|| Sidebar CONTEXT & PROVIDER ||============================== //
const SidebarContext = createContext<SidebarContextType | null>(null);

export const SidebarProvider = ({ children }: { children: React.ReactElement }) => {
    const [sidebarData, setSidebarData] = useState([]);

    const init = async () => {
        try {
            const data = await getSidebarData();
            setSidebarData(data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        init();
    }, []);

    return <SidebarContext.Provider value={{ sidebarData, setSidebarData, init }}>{children}</SidebarContext.Provider>;
};

export default SidebarContext;
