import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Link } from 'react-router-dom';
import Cfc from './Cfc';
import SeedVillage from './SeedVillage';
import BuriedPipe from './BuriedPipe';
import Canal from './Canal';
import Dyke from './Dyke';
import Others from './Others';

const InfrastructureMain: React.FC = () => {
    function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
            </div>
        );
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }
    const tabsOption = [
        {
            label: 'CFC'
        },
        {
            label: 'Seed village'
        },
        {
            label: 'Buried pipe'
        },
        {
            label: 'Canal'
        },
        {
            label: 'Dyke'
        },
        {
            label: 'Others'
        }
    ];

    const theme = useTheme();
    const [value, setValue] = React.useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    sx={{
                        mb: 3,
                        '& a': {
                            minHeight: 'auto',
                            minWidth: 10,
                            py: 1.5,
                            px: 1,
                            mr: 2.25,
                            color: theme.palette.grey[600],
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        },
                        '& a.Mui-selected': {
                            color: theme.palette.success.main
                        },
                        '& .MuiTabs-indicator': {
                            bottom: 2
                        },
                        '& a > svg': {
                            marginBottom: '0px !important',
                            mr: 1.25
                        }
                    }}
                >
                    {tabsOption.map((tab, index) => (
                        <Tab key={index} component={Link} to="#" label={tab.label} {...a11yProps(index)} />
                    ))}
                </Tabs>

                <TabPanel value={value} index={0}>
                    <Cfc />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SeedVillage />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <BuriedPipe />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Canal />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Dyke />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <Others />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default InfrastructureMain;
