import { validationList } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';
import { validationListKey } from '../../../react_query/query-keys';

const useValidationLIst = () => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [validationListKey],
        queryFn: () => validationList(),
        staleTime: 1000 * 60 * 5
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };

}

export default useValidationLIst;