import { useQuery } from '@tanstack/react-query';
import { subActivityBari } from '../../../react_query/query-keys';
import { subActivityCountBari } from '../../../services/reactQueryservices';

const useSubActivityCountBari = (subActivity: string, startYear: string, endYear: string) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [subActivityBari, subActivity, startYear, endYear],
        queryFn: () => subActivityCountBari(subActivity, startYear, endYear),
        enabled: true && !!subActivity,
        staleTime: 300
    });

    return {
        data,
        isFetching,
        error
    };
};

export default useSubActivityCountBari;
