import useInfrastructureList from 'hooks/consume_api/query/useInfrastructureList';
import React from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const BuriedPipe: React.FC = () => {
    const { data, isFetched } = useInfrastructureList('Buried pipe');

    interface IBeneficiary {
        b_first_name: string;
        b_last_name: string;
        b_middle_name: string;
        member_id: string;
        district: string;
        division: string;
        upazila: string;
        union_name: string;
    }
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Beneficiary Id</TableCell>
                        <TableCell align="left">Address</TableCell>
                        <TableCell align="left">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.data &&
                        data?.data.map((item: IBeneficiary, index: number) => (
                            <TableRow>
                                <TableCell align="left">{item.b_first_name + ' ' + item.b_middle_name + ' ' + item.b_last_name}</TableCell>
                                <TableCell align="left">{item.member_id}</TableCell>
                                <TableCell align="left">
                                    {item.division + ',' + item.district + ',' + item.upazila + ',' + item.union_name}
                                </TableCell>
                                <TableCell align="left">
                                    <Button>Gps</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BuriedPipe;
