// material-ui
import { useTheme } from '@mui/material/styles';
import logo from 'assets/images/mpower_logo.png';

const Logo = () => {
    const theme = useTheme();

    // return <img src={logo} alt="CoP" width="100" />;
    return <h2 style={{ color: theme.palette.primary.dark, fontWeight: 'bold' }}>SACP-RAINS</h2>;
};

export default Logo;
