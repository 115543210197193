import { Grid } from '@mui/material';
import React, { useState, useEffect, MouseEvent } from 'react';

import ReactFlow, {
    removeElements,
    addEdge,
    Controls,
    Node,
    Elements,
    Position,
    SnapGrid,
    Connection,
    Edge,
    ReactFlowProvider
} from 'react-flow-renderer';
import ChildNode from './ChildNode';
import DefaultNode from './Default';
import './style.css';
import { deleteLogDefinitionStructure, getLogDefinitionStructure, saveLogDefinitionStructure } from './Service';
import { countUnderscoresAfterSubstring } from 'utils/Helpers';
import useSnackbar from 'hooks/useSnackbar';

const initBgColor = '#f1c751';

const connectionLineStyle = { stroke: '#fff' };
const snapGrid: SnapGrid = [16, 16];
const nodeTypes = {
    childNode: ChildNode,
    root: DefaultNode
};

const ResultTree = (props: any) => {
    const targetRef = React.useRef<HTMLDivElement>(null);
    const [elements, setElements] = useState<Elements>([]);
    const [bgColor] = useState<string>(initBgColor);
    const [node, setNode] = useState<any>(null);
    const [deleteNode, setDeletedNode] = useState<any>(null);
    const [tree, setTree] = useState<any>(null);
    const [preOrderTree, setPreOrderTree] = useState<any>([]);
    const [edited, setEdited] = useState<boolean>(false);

    const toastMsg = useSnackbar();

    const nodeFunctions = (): any => ({ onEdit: onElementsEdit, onRemove: onElementsRemove, onElementsAdd: onElementsAdd });

    useEffect(() => {
        const init = async () => {
            const width = targetRef.current?.offsetWidth;
            let midPosition;
            if (width) {
                midPosition = (width - 150) / 2;
            }

            const response = await getLogDefinitionStructure(props.id);
            console.log('response', response)

            if (response && response.logframeStructures.length > 0) {
                const nodes = response.logframeStructures;
                const nodeElements = response.logframestructureJson;

                const nodeTree = nodes.map((n: any) => {
                    const nodeJson = { ...n.nodeJson };
                    nodeJson.data = { ...nodeJson.data, ...nodeFunctions() };
                    return nodeJson;
                });
                viewChange(nodeElements);
                createTree(nodeTree);
            } else {
                const data = [
                    {
                        id: '1',
                        type: 'root',
                        data: {
                            name: response.name,
                            description: 'root node',
                            isSdgBased: false,
                            onEdit: onElementsEdit,
                            onRemove: onElementsRemove,
                            color: initBgColor,
                            onElementsAdd: onElementsAdd,
                            level: 1,
                            width: 150
                        },
                        position: { x: midPosition ? midPosition : 250, y: 2 },
                        sourcePosition: Position.Bottom
                    }
                ];
                console.log(data, "======log framedata========================================")
                setElements(data);
                createTree(data);
            }
        };
        init();
    }, []);

    const viewChange = (_elements: any) => {
        console.log(_elements, '==========ee=====');
        _elements = _elements.map((e: any) => {
            if (e.data) {
                e.data = { ...e.data, ...nodeFunctions() };
                return e;
            }
            return e;
        });
        setElements(_elements);
    };

    const save = async (data: any) => {
        console.log('request data: ', data);
        const response = await saveLogDefinitionStructure(props.id, data);
        // console.log('log frame definition: ', response);
    };

    const createTree = (data: any) => {
        const elementList = data.filter((obj: any) => obj.type == 'childNode' || obj.type == 'root');
        const treeData: any = [];
        elementList.forEach((elem: any) => {
            const listData = data.filter((obj: any) => obj.source == elem.id);
            const children = listData.map((obj: any) => obj.target);
            treeData.push({
                ...elem,
                children: children
            });
        });

        console.log('final treeData formed:::: ', treeData);

        setTree(treeData);
        const element: any = data.find((obj: any) => obj.type == 'root');
        const root = treeData.find((obj: any) => obj.id == element.id);
        preOrderTraverse(root, []);
    };

    const handleChange = (xPos: number, nodeItem: any, nodeWidth: number) => {
        setElements((els) =>
            els.map((el) => {
                if (el.id == nodeItem.id) {
                    const position = {
                        x: xPos,
                        y: nodeItem.position.y
                    };
                    const data = {
                        ...el.data,
                        width: nodeWidth
                    };

                    // it's important that you create a new object here
                    // in order to notify react flow about the change
                    el = {
                        ...el,
                        position: position,
                        data: data
                    };
                }

                return el;
            })
        );
    };

    const preOrderTraverse = (nodeItem: any, preOrder: any) => {
        const currentNode = nodeItem;
        if (currentNode) {
            const data = preOrder;
            data.push(currentNode);
            setPreOrderTree(data);
            for (let i = 0; i < currentNode.children.length; i += 1) {
                const newNode = tree.find((obj: any) => obj.id == currentNode.children[i]);
                newNode && preOrderTraverse(newNode, data);
            }
        }
    };

    useEffect(() => {
        if (node) {
            const result = tree.filter((t: any) => t.data.name === node.data.name);

            if (result.length > 0) {
                alert(`a node is already exist with the same name '${node.data.name}'. please choose another name`);
                const _elements = [...elements];
                const elementIndex = elements.findIndex((e: any) => e.id == node.id);
                const edgeIndex = elements.findIndex((e: any) => e.target == node.id);
                _elements.splice(elementIndex, 1);
                _elements.splice(edgeIndex, 1);

                console.log(_elements, node);
                setElements(_elements);
                return;
            }
            // else
            const edge: any = elements.find((obj: any) => obj.target == node.id);
            if (edge) {
                const treeData = [...tree];
                treeData.push({
                    ...node,
                    children: []
                });
                const parent = tree.findIndex((obj: any) => obj.id == edge.source);
                treeData[parent].children.push(node.id);
                const derivedResult = { tree: treeData, viewJson: elements };
                setTree(treeData);
                save(derivedResult);
            }
            const element: any = elements.find((obj: any) => obj.type == 'root');
            const root = tree.find((obj: any) => obj.id == element.id);
            preOrderTraverse(root, []);
            redecorate(node);
        }
    }, [node]);

    useEffect(() => {
        if (deleteNode) {
            removeChild(deleteNode[0]);
            createTree(elements);
        }
    }, [deleteNode]);

    const removeChild = (deleteNodeItem: any) => {
        const treeNode = tree.find((obj: any) => obj.id == deleteNodeItem.id);
        treeNode.children.forEach((elem: any) => {
            const element = elements.find((obj: any) => obj.id == elem);
            onElementsRemove([element]);
            removeChild(element);
        });
    };

    useEffect(() => {
        if (preOrderTree.length > 0 && node) {
            redecorate(node);
            setNode(null);
        }
    }, [preOrderTree]);

    const onElementsRemove = async (elementsToRemove: any) => {
        if (elementsToRemove?.length > 0) {
            const response = await deleteLogDefinitionStructure(props.id, { nodeId: elementsToRemove[0]?.id });
            if (response.status === 200) {
                setDeletedNode(elementsToRemove);
                toastMsg('node deleted!');
                return setElements((els) => removeElements(elementsToRemove, els));
            } else {
                toastMsg(response?.text, 'error');
            }
        }
    };

    const onElementsEdit = (id: string, data: any) => {
        setTree((prevList: any[]) => {
            return prevList.map((item: any) => {
                if (item.id === id) {
                    item.data = data;
                }
                return item;
            });
        });
        setElements((els) =>
            els.map((el) => {
                if (el.id === id) {
                    // it's important that you create a new object here
                    // in order to notify react flow about the change
                    el.data = data;
                } else if (countUnderscoresAfterSubstring(el.id, id) === 1) {
                    el.data.parent = data.name;
                }

                return el;
            })
        );
        setEdited(true);
    };

    useEffect(() => {
        const editStructure = async () => {
            await save({ tree, viewJson: elements });
        };
        if (edited) {
            editStructure();
            setEdited(false);
        }
    }, [elements, edited]);

    const onNodeDragStop = (_: MouseEvent, nodeItem: Node) => {
        // setNode(node);
        console.log(elements, nodeItem);
        const newElements = [...elements];
        const idx = newElements.findIndex((el: any) => el.id == nodeItem.id);
        newElements.splice(idx, 1, nodeItem);
        console.log(tree);
        viewChange(newElements);
        console.log(JSON.stringify({ tree, viewJson: newElements }));
        save({ tree, viewJson: newElements });
    };

    const onElementsAdd = (source: any, newNode: any) => {
        setElements((es) => es.concat(newNode));
        setNode(newNode);
        const edge = {
            id: `e${source}-${newNode.id}`,
            source: source,
            target: newNode.id,
            style: { stroke: '#34347d' }
        };
        onConnect(edge);
    };

    const redecorate = (newNode: any) => {
        const width = targetRef.current?.offsetWidth;
        const filtered = preOrderTree.filter((elem: any) => elem.data.level == newNode.data.level);
        const count = filtered.length;
        if (width && count) {
            const pad = Math.round(width / 60);
            const usableWidth = width - pad;
            const nodeGap = Math.round(usableWidth / count);
            let initial = 0;
            for (let i = 0; i < count; i += 1) {
                handleChange(initial, filtered[i], nodeGap - pad);
                initial += nodeGap;
            }
        }
    };

    const onConnect = (params: Connection | Edge) => setElements((els) => addEdge(params, els));

    return (
        <Grid item={true} xs={12} className="zoompanflow" style={{ height: '80%' }}>
            <ReactFlowProvider>
                <div className="reactflow-wrapper">
                    <ReactFlow
                        ref={targetRef}
                        className={'react-flow__node-default react-flow react-flow__node'}
                        elements={elements}
                        onConnect={onConnect}
                        onNodeDragStop={onNodeDragStop}
                        style={{
                            background: bgColor,
                            position: 'relative',
                            height: '90vh',
                            width: '100%'
                        }}
                        nodeTypes={nodeTypes}
                        connectionLineStyle={connectionLineStyle}
                        snapToGrid={true}
                        snapGrid={snapGrid}
                        defaultZoom={1}
                    >
                        <Controls />
                    </ReactFlow>
                </div>
            </ReactFlowProvider>
        </Grid>
    );
};

export default ResultTree;
