import React from 'react'
import { permissionList } from 'services/reactQueryservices';
import { permissionListKey } from '../../../react_query/query-keys';
import { useQuery } from '@tanstack/react-query';

const usePermission = (role_id: number) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [permissionListKey, role_id],
        queryFn: () => permissionList(role_id),
        enabled: !!role_id,
        staleTime: 60 * 60 * 1000
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };
}

export default usePermission