import { useQuery } from '@tanstack/react-query';
import { memberList } from 'services/reactQueryservices';

import { memberInstanceList } from 'react_query/query-keys';
import { getMemberList } from 'services/sacp';

const useMemberList = (
    page: number,
    limit: number,
    division_id: string,
    district_id: string,
    upazila_id: string,
    union_id: string,
    firstName: string
) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [memberInstanceList, page, limit, division_id, district_id, upazila_id, union_id, firstName],
        queryFn: () => getMemberList({ page, limit, division_id, district_id, upazila_id, union_id, firstName })
    });

    return {
        data,
        isFetching,
        error
    };
};

export default useMemberList;
