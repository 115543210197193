export const badcData = [
    // {
    //     label: 'Formation and development of Smallholder Water User (789 No.) and Seed village (30 No.) Groups',
    //     projectName: 'SACP',
    //     unit: 'Group'
    // },
    {
        label: 'Training of Water User Groups(WUGs)',
        projectName: 'SACP',
        unit: 'Group',
        valueText: '117'
    },
    {
        label: 'Oversea training (5 batch, 8 person)',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '118'
    },
    {
        label: 'Seminar /workshop',
        projectName: 'SACP',
        unit: 'year',
        valueText: '119'
    },
    {
        label: 'Mid Turm Evaluation',
        projectName: 'SACP',
        unit: 'No',
        valueText: '120'
    },
    {
        label: 'Progress Monitoring',
        projectName: 'SACP',
        unit: 'No',
        valueText: '121'
    },
    // {
    //     label: 'Training of Trainer',
    //     projectName: 'SACP',
    //     unit: 'No'
    // },
    {
        label: 'Crop protection earthen dyke- avg 31500cum/kM (Leveling & Dressing-6% and Survey, drawing & degin-4%)',
        projectName: 'SACP',
        unit: 'No',
        valueText: '122'
    },
    {
        label: 'Re-excavation of Small Width Khal/Chara- avarage 10000cum/kM  (Leveling & Dressing-6% and Survey, drawing & degin-4%)',
        projectName: 'SACP',
        unit: 'KM',
        valueText: '123'
    },
    {
        label: 'Re-excavation of Medium Width Khal/Chara- avarage 12000cum/kM (Leveling & Dressing-6% and Survey, drawing & degin-4%)',
        projectName: 'SACP',
        unit: 'KM',
        valueText: '124'
    },
    {
        label: 'Installation of buried pipe line & Pump House',
        projectName: 'SACP',
        unit: 'KM & No',
        valueText: '125'
    },
    {
        label: 'Community pond excavation with homestead solar irrigation pump sets',
        projectName: 'SACP',
        unit: 'No',
        valueText: '126'
    },
    {
        label: 'Extension of buried pipe line for existing BADC scheme',
        projectName: 'SACP',
        unit: 'KM',
        valueText: '127'
    },
    {
        label: 'Rain water harvester installation',
        projectName: 'SACP',
        unit: 'No of site',
        valueText: '128'
    },
    {
        label: 'Construction of On Farm Water Management structure (Medium size-3/4 vent Regulator)',
        projectName: 'SACP',
        unit: 'No',
        valueText: '129'
    },
    {
        label: 'Construction of On Farm Water Management structure (Cattle Crossing/foot bridge/small-1/2 vent Regulator etc)',
        projectName: 'SACP',
        unit: 'No',
        valueText: '130'
    },
    {
        label: 'Construction of On Farm Water Management structure (water pass/equalizer/pipe culvert)',
        projectName: 'SACP',
        unit: 'No',
        valueText: '131'
    },
    {
        label: 'Construction of dug well with solar driven pump',
        projectName: 'SACP',
        unit: 'No',
        valueText: '132'
    },
    {
        label: 'Community Artisian well installation with drip Irrigation',
        projectName: 'SACP',
        unit: 'No',
        valueText: '133'
    },
    {
        label: 'Establishment of seed villages (to produce foundation seeds',
        projectName: 'SACP',
        unit: 'No',
        valueText: '134'
    },
    {
        label: 'Construction of boundary wall for South Central',
        projectName: 'SACP',
        unit: 'm',
        valueText: '135'
    },
    {
        label: 'Construction of Electric Infrastructure (Transformer & Related Accessories for 1 & 2 Cusec).',
        projectName: 'SACP',
        unit: 'No',
        valueText: '136'
    }
];

export const gainData = [
    {
        label:
            'Formative research (A.1 Formative research (assessment) to integrate farmer’s needs/demands/views to contextualize existing training/communication modules and tools.)',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '208'
    },
    {
        label: `A.2 Staff training module/tool revision and contextualize based on formative research and using GAIN's existing nutrition sensitive programming manual/tools as well as nutrition specific activities.`,
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '209'
    },
    {
        label: `A.5 Farmers SBCC module/tool revision and contextualize based on formative research and GAIN's existing tools: demo for farmer/women, e-learning for adolescent/youth, workforce nutrition tool for farme`,
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '210'
    },
    {
        label: `A.9 Field support/monitoring to farmers groups on technical guidance for organizing nutrition interventions/activities.`,
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '211'
    },
    {
        label: `B.6 Field support/monitoring to Union MSPs to lead nutrition planning and implementation.`,
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '212'
    },
    {
        label: `A.3 Technical consultation workshop to finalize adopted/drafted module with relevant experts`,
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '213'
    },
    {
        label: `B.5 Facilitate Union-MSP to advocate their nutrition agenda at the national level stakeholders (both government and private agencies includes BNNC)`,
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '214'
    },
    {
        label: `A.7 SBCC with farmers groups aligning with GAIN's workforce nutrition (WFN) learning process led by champion farmers/peer educators and connecting WFN social media network and competition with farmers groups. (1 group session per quarter)`,
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '215'
    },
    {
        label: `A.8 Engage youths and adolescents in GAIN's eat well live well campaign, e-learning followed by nutrition fair (sharing nutrition fact of seasonal foods, food choice Apps, cooking demo etc.) at the Union level.`,
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '216'
    },
    {
        label: `B.1 Development of Union-MSP on nutrition programming with Standard Operating Procedure (SOP) based on GAIN's current approach on local nutrition governance`,
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '217'
    },
    {
        label: `B.3 Facilitate UP to develop Annual Nutrition Plan in consultation with communities during ward level demand assessment.`,
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '218'
    },
    {
        label: `B.4 Union MSP Annual Nutrition Plan Review workshop (half-yearly) linking with Upazila- and District Nutrition Coordination Committee (UNCC & DNCC), and other relevant line agencies and private sectors`,
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '219'
    }
];

export const bariData = [
    {
        label: 'Action research on appropriate technologies for HVCs',
        projectName: 'SACP',
        unit: 'No',
        valueText: '73'
    },
    {
        label:
            'Breeder seed production of pulses, oilseeds, vegetables and other potential HVCs for BADC, seed villages, research, production programmes  and demonstrations',
        projectName: 'SACP',
        unit: 'MT',
        valueText: '74'
    },
    {
        label: 'Research on agro-processing',
        projectName: 'SACP',
        unit: 'No',
        valueText: '75'
    },
    {
        label:
            'Multiplication and distribution of various HYV fruits saplings of germplasm/variety for mother orchard establishment and management',
        projectName: 'SACP',
        unit: 'No',
        valueText: '76'
    },
    {
        label: 'Action research on market driven HVCs',
        projectName: 'SACP',
        unit: 'No',
        valueText: '77'
    },
    {
        label:
            'Farmers Field trial on stress tolerant varieties/ adaptation technologies of fruits, vegetables, oilseed, cereal crops, etc. with production programme of promising technologies',
        projectName: 'SACP',
        unit: 'No',
        valueText: '78'
    },
    {
        label: 'Farmers Field Trial on appropriate scale mechanization',
        projectName: 'Sacp',
        unit: 'No',
        valueText: '79'
    },
    {
        label: 'Publication of the technology manual/booklet/fact sheet for training, demonstration and field trial and report publication',
        projectName: 'SACP',
        unit: 'No',
        valueText: '80'
    },
    {
        label: 'Capacity Building of Scientist (Oversea Training/Exposure Visit)',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '81'
    },
    {
        label:
            'Training of Trainers on Improved Farm Power Machinery, Irrigation & Water Management, Post Harvest & Processing Technology and crop production Technology',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '82'
    },
    {
        label: 'Field Day on Improved Farm Power Machinery, Irrigation & Water Management and crop production Technology',
        projectName: 'SACP',
        unit: 'No',
        valueText: '83'
    },
    {
        label: 'Workshop on Identification, prioritization and selection of proven technologies, and progress review',
        projectName: 'Sacp',
        unit: 'No',
        valueText: '84'
    },
    {
        label: 'Workshop on Adaptation, improvement, capacity building and dissemination of scale appropriate farm machinery',
        projectName: 'SACP',
        unit: 'No',
        valueText: '85'
    },
    {
        label: 'Renovation, repair and improvement of Lab Machineries and Equipments (BARI central Lab & Seed Tech Div.)',
        projectName: 'SACP',
        unit: 'No',
        valueText: '87'
    },
    {
        label: 'Renovation, repair and improvement of Lab building and structures (BARI central Lab & Seed Tech Div.)',
        projectName: 'SACP',
        unit: 'No',
        valueText: '86'
    }
];

export const daeData = [
    {
        label: 'Fruit Garden',
        projectName: 'SACP',
        unit: 'Number',
        valueText: '1'
    },
    {
        label: 'Vegetables demonstration',
        projectName: 'SACP',
        unit: 'Number',
        valueText: '2'
    },
    {
        label: 'Others demonstration',
        projectName: 'SACP',
        unit: 'Number',
        valueText: '3'
    },
    {
        label: 'Homestead Vegetables Garden setup',
        projectName: 'SACP',
        unit: 'Number',
        valueText: '4'
    },
    {
        label: 'Special support for Summer Tomato cultivation at farmers level',
        projectName: 'SACP',
        unit: 'Number',
        valueText: '5'
    },
    {
        label: 'Quick Impact inputs to household and lead farmers to increase food security and access to Market',
        projectName: 'SACP',
        unit: 'Number',
        valueText: '6'
    },
    {
        label: 'PO engagement and strengthening (Workshop/Exchange visit)',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '7'
    },
    {
        label: 'PO support in operational equipment',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '8'
    },
    {
        label: 'Homestead Vegetables Garden (HVG) Kits',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '9'
    },
    {
        label: 'Preparation of technical packages with suitable varieties & technologies',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '10'
    },
    {
        label: 'Farmer Adaptation of proven technical packages (Climate smart technical package)',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '11'
    },
    {
        label: 'Group level small tools and equipment - integrated farming model',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '12'
    },
    {
        label: 'Group level small tools and equipment - women model',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '13'
    },
    {
        label: 'Youth model farm equipment',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '14'
    },
    {
        label: 'Youth model farm arrangement',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '15'
    },
    {
        label: 'Setting up demonstration sites in proximity to farmers  (Year-Round Homestead Fruit & Vegetables Gardening)',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '16'
    },
    {
        label: 'Setting up demonstrations on Crops and  to support diffusion and adoption',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '17'
    },
    {
        label: 'Fruits',
        projectName: 'SACP',
        unit: 'Number',
        valueText: '18'
    },
    {
        label: 'Vegetables',
        projectName: 'SACP',
        unit: 'Number',
        valueText: '19'
    },
    {
        label: 'Others (block demo of pulses, oil crops, maize and compost etc.)',
        projectName: 'SACP',
        unit: 'Number',
        valueText: '20'
    },
    {
        label: 'Field days on crops adaptation',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '21'
    },
    {
        label: '2-days center based training for farmer group leaders (President/Secretary/ Cashier/Lead Farmers)',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '22'
    },
    {
        label: '1-day training for women farmer groups',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '23'
    },
    {
        label: '1 day home level training for women',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '24'
    },
    {
        label: '2-days women based training in development of healthy horticulture based recipe',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '25'
    },
    {
        label: '1/2 day women farmer demonstrations on safe food recipe preparation and competition',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '26'
    },

    {
        label: '1/2 day training for women farmers on nutrition based cooking competition',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '27'
    },
    {
        label: 'Farmers Business School (FBS)',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '28'
    },
    {
        label: 'Farmers Field Business School (FFBS)',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '29'
    },
    {
        label: 'F2F exchange visit',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '30'
    },
    {
        label: 'Exchange Visit (In-country) for SAAOs',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '31'
    },
    {
        label: 'On Demand Technical Training to Farmers',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '32'
    },
    {
        label: 'Briefing/ Training session on Homestead Vegetables Gardening  (HVG Special Program)',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '33'
    },
    {
        label: 'Training session on year round nutrition based Homestead Vegetables Gardening  ( HVG Special Program  )',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '34'
    },
    {
        label: 'Season Long ToT on ML-FFS for SAAOs',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '35'
    },
    {
        label: 'Season Long ToT on FFBS for SAAOs',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '36'
    },
    {
        label: 'ToT for SAAOs on High Value Crops (HVCs) Production Technology and  community based safe food processing (4 days long)',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '37'
    },
    {
        label: 'Exposure visit and training outside the country: extension practitioner (DAE) with farmers',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '38'
    },
    {
        label: 'Homestead gardens Training (TOT and Cascade, training materials)',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '39'
    },
    {
        label: 'Nutrition awareness and SBCC',
        projectName: 'RAINS',
        unit: 'LS',
        valueText: '40'
    },
    {
        label: 'Capacity building on multi-sectoral coordination',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '41'
    },
    {
        label: 'Training on multi-dimension development',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '42'
    },
    {
        label: 'Climate-nutrition integrated farming model',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '43'
    },
    {
        label: 'Women-exclusive integrated homestead garden model',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '44'
    },
    {
        label: 'Youth on-farm individual model',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '45'
    },
    {
        label: 'National and divisional capacity building',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '46'
    },
    {
        label: 'Multi-dimensional training of technical staf',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '47'
    },
    {
        label: 'Introduce Farmer - Extension - Research/Academia – Private Sector Linkages (with FAO-TA)  ',
        projectName: 'RAINS',
        unit: 'Sessions',
        valueText: '48'
    },
    {
        label: 'Adoption of a decentralized extension approach',
        projectName: 'RAINS',
        unit: 'Sessions',
        valueText: '49'
    },
    {
        label: 'Participatory Rural Appraisal (PRA) for CIG and CMG groups mobilization.',
        projectName: 'SACP',
        unit: 'Number',
        valueText: '50'
    },
    {
        label: 'Selection, mobilization and training',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '51'
    },
    {
        label: 'Market-led Research on identification, assessment and prioritization of HVC Value Chains',
        projectName: 'SACP',
        unit: 'Number',
        valueText: '52'
    },
    {
        label: 'Renovation of DAE office and training facility',
        projectName: 'SACP',
        unit: 'LS',
        valueText: '53'
    },
    {
        label: 'Solar Pump Based Solar home system ( Pumpset with Accessories ) supply with Installation',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '54'
    },
    {
        label: 'Follow-up and maintenance service charge for solar home system',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '55'
    },
    {
        label: 'Grassroots service outlets',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '56'
    },
    {
        label: 'Establish Common Facility Center on cost sharing',
        projectName: 'SACP',
        unit: 'No',
        valueText: '57'
    },
    {
        label: 'Higher Study (PhD)',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '104'
    },
    {
        label: 'Baseline, Midterm and End line Survey',
        projectName: 'SACP',
        unit: 'No',
        valueText: '58'
    },
    {
        label: 'Baseline, Midterm and End line Survey',
        projectName: 'RAINS',
        unit: 'No',
        valueText: '64'
    },
    {
        label: 'Annual Outcome Survey',
        projectName: 'SACP',
        unit: 'No',
        valueText: '65'
    },
    {
        label: 'Annual Outcome Survey',
        projectName: 'RAINS',
        unit: 'No',
        valueText: '59'
    },
    {
        label: 'Others studies and surveys',
        projectName: 'SACP',
        unit: 'LS',
        valueText: '60'
    },
    {
        label: 'Policy engagement and COSOP activities',
        projectName: 'SACP',
        unit: 'LS',
        valueText: '61'
    },
    {
        label: 'Country Program support unit',
        projectName: 'SACP',
        unit: 'LS',
        valueText: '62'
    },
    {
        label: 'Thematic studies (nutrition, gender, youth, climate, etc.)',
        projectName: 'RAINS',
        unit: 'LS',
        valueText: '63'
    },
    {
        label: 'Workshop at national level',
        projectName: 'SACP',
        unit: 'No',
        valueText: '67'
    },
    {
        label: 'Workshop at national level',
        projectName: 'RAINS',
        unit: 'No',
        valueText: '71'
    },
    {
        label: 'Workshop at district level',
        projectName: 'SACP',
        unit: 'No',
        valueText: '68'
    },
    {
        label: 'Annual Supervision Workshop at Region',
        projectName: 'SACP',
        unit: 'No',
        valueText: '69'
    },
    {
        label: 'Annual Supervision Workshop at Region',
        projectName: 'RAINS',
        unit: 'No',
        valueText: '72'
    },
    {
        label: 'Retreat - Workshop /Writeshop',
        projectName: 'SACP',
        unit: 'No',
        valueText: '70'
    }
];

export const faoData = [
    {
        label: 'SACP: Training of Trainers (ToT) for SAAOs on PRA and group mobilization (FAO)',
        projectName: 'SACP',
        unit: 'No',
        valueText: '138'
    },
    {
        label: 'SACP: ToT on Market Led FFS for Master trainer (FAO)',
        projectName: 'SACP',
        unit: 'Number',
        valueText: '158'
    },
    {
        label: 'RAINS: ToT for DAE staff on PRA process (3 days; 60 Upazila x 5 SAAOs = 300; 30 persons in each batch = 10 batches)',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '139'
    },
    {
        label:
            'RAINS: Support value chain studies, including buyer mapping, for selected agricultural commodities  and dissemination workshop (in the selected districts)',
        projectName: 'RAINS',
        unit: 'LS',
        valueText: '141'
    },
    {
        label: 'RAINS: Technical workshop with DAE & DAM staff and elected representatives from each PO',
        projectName: 'RAINS',
        unit: 'Event',
        valueText: '142'
    },
    {
        label: 'RAINS: Knowledge exchange with Sara Bangla Krishak Society for DAE & DAM staff as well as FBFs and PO representatives',
        projectName: 'RAINS',
        unit: 'Event',
        valueText: '143'
    },
    {
        label:
            'RAINS: Additional training as needed on PO governance, bookkeeping and business development for DAE & DAM staff, FBFs, PO representatives, lead FG',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '145'
    },
    {
        label:
            'RAINS: Training of Farmer Business Facilitators FBFs, example (MMI approach, Rural invest ect) (at least one FBF per district)',
        projectName: 'RAINS',
        unit: 'LoA',
        valueText: '144'
    },
    {
        label:
            'RAINS: Additional training as needed on PO governance, bookkeeping and business development for DAE & DAM staff, FBFs, PO representatives, lead FG',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '145'
    },
    {
        label: 'RAINS: Exposure and knowledge exchange visits to successful PO',
        projectName: 'RAINS',
        unit: 'Event',
        valueText: '146'
    },
    {
        label: 'RAINS: THEORY- ToT for DAE-SAAOs on the establishment of the integrated homestead garden models',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '149'
    },
    {
        label:
            'RAINS: PRACTICAL- Establishment of integrated homestead garden model demonstrations through LoA-Training of selected FGs in homestead garden models: 500 demo home-garden (1 per union)',
        projectName: 'RAINS',
        unit: 'No',
        valueText: '150'
    },
    {
        label: 'RAINS: Establishing homestead gardens demo plots (2 per upazilla)',
        projectName: 'RAINS',
        unit: 'No',
        valueText: '151'
    },
    {
        label: 'RAINS: Refresher training/workshop for FFS Master Trainers (1 day, 4 batches of 30 persons)',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '154'
    },
    {
        label: 'RAINS: Exposure visits to water-saving irrigation sites used for growing HVC',
        projectName: 'RAINS',
        unit: 'Event',
        valueText: '155'
    },
    {
        label:
            'RAINS: Needs-based training to enable access and use of existing government-endorsed online and offline apps (Digital Literacy)',
        projectName: 'RAINS',
        unit: 'LS',
        valueText: '156'
    },
    {
        label: 'RAINS: Institutional assessment to identify capacity needs in DAE to support community based rural organizations',
        projectName: 'RAINS',
        unit: 'LS',
        valueText: '160'
    },
    {
        label: 'RAINS: Needs based 5 days long ToT for DAE/DAM staff considering nutrition and food safety issues',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '162'
    },
    {
        label: 'SACP: Training on Buyer mapping methodology',
        projectName: 'SACP',
        unit: 'No',
        valueText: '163'
    },
    {
        label: 'SACP: Training on buyer requirement and marketing facilitation',
        projectName: 'SACP',
        unit: 'No',
        valueText: '164'
    },
    {
        label: 'SACP: ToT on postharvest primary processing, food safety and quality',
        projectName: 'SACP',
        unit: 'No',
        valueText: '170'
    },
    {
        label: 'SACP: ToT on rural investment module 2 and 3',
        projectName: 'SACP',
        unit: 'No',
        valueText: '175'
    },
    {
        label: 'SACP: ToT on rural investment module 2 and matching grant implementation',
        projectName: 'SACP',
        unit: 'No',
        valueText: '176'
    },
    {
        label: 'SACP: 5 days centered based training for resource persons',
        projectName: 'SACP',
        unit: 'No',
        valueText: '178'
    },
    {
        label: 'SACP: ToT on community based safe food processing',
        projectName: 'SACP',
        unit: 'No',
        valueText: '181'
    },
    {
        label: 'SACP: ToT on product and nutrient labelling (3 days)',
        projectName: 'SACP',
        unit: 'No',
        valueText: '183'
    },
    {
        label:
            'RAINS: ToT on improved post-harvest management, storage and primary processing (3 days training; 4 batches of 30-35 persons each - including FBS and FFS MTs)',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '186'
    },
    {
        label: `RAINS: Support to the organization and facilitation of multi-stakeholder 'Value Chain Roundtables' (2 events per district)`,
        projectName: 'RAINS',
        unit: 'Event',
        valueText: '187'
    },
    {
        label: 'RAINS: Support for e-commerce, branding, certification',
        projectName: 'RAINS',
        unit: 'LS',
        valueText: '188'
    },
    {
        label:
            'RAINS: Training on small-scale food processing business planning and facilitation (2 sites  per district, 10 persons per site, total 280 person; in 14 batches of 20)',
        projectName: 'RAINS',
        unit: 'MM',
        valueText: '189'
    },
    {
        label:
            'RAINS: Suport to establishment of youth managed agricultural machinery and equipment services (1 demo per district, training in 7 batches, 4 sessions)',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '190'
    },
    {
        label: 'SACP: ToT for upazila BADC & DAE staff on water user group mobilization & facilitation',
        projectName: 'SACP',
        unit: 'LS',
        valueText: '192'
    },
    {
        label: 'SACP: Seed village training of trainers (in 3 batches of 25 villages)',
        projectName: 'SACP',
        unit: 'LS',
        valueText: '195'
    },
    {
        label: 'SACP: Training of trainers (3 days) on business plans and O&M for irrigation schemes for lead farmers ',
        projectName: 'SACP',
        unit: 'LS',
        valueText: '196'
    },
    {
        label: 'RAINS: Demand based Support the PMU, TAC and PSC and government to address relevant emerging  issues',
        projectName: 'RAINS',
        unit: 'LS',
        valueText: '200'
    }
];

export const damData = [
    {
        label: 'Quarterly meeting for creation of multistakeholder',
        projectName: 'SACP',
        unit: 'Meeting',
        valueText: '88'
    },
    {
        label: 'Progress Monitoring',
        projectName: 'SACP',
        unit: 'No',
        valueText: '90'
    },
    {
        label: 'Mid Term Evaluation',
        projectName: 'SACP',
        unit: 'No',
        valueText: '91'
    },
    {
        label: 'Agreement with public and private buyers',
        projectName: 'SACP',
        unit: 'No',
        valueText: '92'
    },
    {
        label: 'Workshop (Upazilla Level)',
        projectName: 'SACP',
        unit: 'No',
        valueText: '93'
    },
    {
        label: ' Workshop (District/Division/Head Office)',
        projectName: 'SACP',
        unit: 'No',
        valueText: '94'
    },
    {
        label: ' Workshop (District/Division/Head Office)',
        projectName: 'RAINS',
        unit: 'No',
        valueText: '95'
    },
    {
        label: 'Establish small scale food processing business model demonstration sites',
        projectName: 'RAINS',
        unit: 'Demos',
        valueText: '96'
    },
    {
        label: 'Support pilots of youth managed machinery services (including repair services)',
        projectName: 'RAINS',
        unit: 'Demos',
        valueText: '97'
    },
    {
        label: 'TOT for DAM officials and SAAOs on business management skills',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '98'
    },
    {
        label: 'Farmer Training on business management skills',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '99'
    },
    {
        label: 'TOT for DAM and other agencies Officials  on Entrepreneurship Development',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '100'
    },
    {
        label: 'Farmers Training on Entrepreneurship Development',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '101'
    },
    {
        label: 'TOT for DAM officials and SAAOs on postharvest and primary processing',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '102'
    },
    {
        label: 'Farmer training on postharvest and primary processing',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '103'
    },
    {
        label: 'Higher Study (PhD)',
        projectName: 'SACP',
        unit: 'No',
        valueText: '104'
    },
    {
        label: 'Foreign Exposure Visit',
        projectName: 'SACP',
        unit: 'Batch',
        valueText: '105'
    },
    {
        label: 'Farmers Training on Business Management Skill development  (RAINS)',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '106'
    },
    {
        label: 'Marketing promotion events',
        projectName: 'RAINS',
        unit: 'Sessions',
        valueText: '107'
    },
    {
        label: 'Replication of women market stands',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '108'
    },
    {
        label: 'Start-up package (2 groups of 30 pax in 14 districts) for women',
        projectName: 'RAINS',
        unit: 'Group',
        valueText: '109'
    },
    {
        label: 'Training of  the youth and women groups',
        projectName: 'RAINS',
        unit: 'Batch',
        valueText: '110'
    },
    {
        label: 'Start-up package (2 groups of 30 pax in 14 districts)  for youth',
        projectName: 'RAINS',
        unit: 'Group',
        valueText: '111'
    },
    {
        label: 'Grant support for Promotion of agro-processing enterprises',
        projectName: 'RAINS',
        unit: 'Number',
        valueText: '113'
    },
    {
        label: `Access to market of proximity and collection points/ farmer's hub one stop solution)`,
        projectName: 'RAINS',
        unit: 'Points',
        valueText: '114'
    }
];
