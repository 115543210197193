import {SACPGroupDetails} from "../types";

export const BASEURL = process.env.REACT_APP_SERVICE_URL;
export const IMAGEPATH = process.env.IMAGE_BASE_URL;

export const PAGE_SIZE = 10;
export const CURRENT_PAGE = 1;
export const DEFAULT_STALE_TIME = 1000 * 60 * 5;

export const STATUS_COLOR: { [key: string]: string } = {
    "new": 'secondary',
    "inProgress": "primary",
    "done": "success"
}

export const SACP_GROUP_DETAILS: SACPGroupDetails[] = [
    {
        id: "1",
        name: "Service Group 1",
        groupPurpose: "Community Service",
        groupType: {
            id: "101",
            title: "Volunteer Group"
        },
        location: "City Center",
        groupPresident: {
            id: "u1",
            username: "president1",
            firstName: "John",
            lastName: "Doe"
        },
        groupSecretary: {
            id: "u2",
            username: "secretary1",
            firstName: "Jane",
            lastName: "Smith"
        },
        GroupCashier: {
            id: "u3",
            username: "cashier1",
            firstName: "Alex",
            lastName: "Johnson"
        },
        participant: [
            {
                id: "u4",
                username: "participant1",
                firstName: "Mark",
                lastName: "Williams"
            },
            {
                id: "u5",
                username: "participant2",
                firstName: "Emily",
                lastName: "Jones"
            }
        ]
    },
    {
        id: "2",
        name: "Green Initiative",
        groupPurpose: "Environmental Conservation",
        groupType: {
            id: "102",
            title: "Green Initiative"
        },
        location: "Park District",
        groupPresident: {
            id: "u6",
            username: "president2",
            firstName: "Sarah",
            lastName: "Brown"
        },
        groupSecretary: {
            id: "u7",
            username: "secretary2",
            firstName: "Michael",
            lastName: "Taylor"
        },
        GroupCashier: {
            id: "u8",
            username: "cashier2",
            firstName: "Olivia",
            lastName: "Clark"
        },
        participant: [
            {
                id: "u9",
                username: "participant3",
                firstName: "Ryan",
                lastName: "Miller"
            },
            {
                id: "u10",
                username: "participant4",
                firstName: "Sophia",
                lastName: "Martinez"
            }
        ]
    },
]