import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField, useTheme } from '@mui/material';
import { ColorResult, TwitterPicker } from 'react-color';

interface UpdateDialogProps {
    open: boolean;
    onOkay: any;
    onClose: any;
    closeText: string;
    headerText: string;
    nodeInfo: any;
    setValue: any;
}

const UpdateDialog: React.FC<UpdateDialogProps> = (props: UpdateDialogProps) => {
    const theme = useTheme();

    const handleChangeBackgroundColor = (color: ColorResult) => {
        props.setValue({
            target: {
                name: 'color',
                value: color.hex
            }
        });
    };

    console.log('---------- in updateDialog: ', props.nodeInfo);

    return (
        <div>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
                    {' '}
                    {props.headerText}{' '}
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden' }}>
                    <Grid
                        container={true}
                        item={true}
                        spacing={5}
                        // justify="center"
                        alignContent="center"
                        alignItems="center"
                        style={{ padding: theme.spacing(3), overflow: 'hidden' }}
                    >
                        {props.nodeInfo.parent && (
                            <Grid item={true} xs={12} sm={12}>
                                <TextField
                                    name="parent"
                                    variant="outlined"
                                    required={true}
                                    fullWidth={true}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    value={props.nodeInfo.parent || ''}
                                    onChange={props.setValue}
                                    label="Parent Name"
                                />
                            </Grid>
                        )}
                        <Grid item={true} xs={12} sm={12}>
                            <TextField
                                name="name"
                                variant="outlined"
                                required={true}
                                fullWidth={true}
                                value={props.nodeInfo.name || ''}
                                onChange={props.setValue}
                                label="Node Name"
                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={12}>
                            <TextField
                                name="description"
                                variant="outlined"
                                multiline
                                rows={3}
                                fullWidth={true}
                                value={props.nodeInfo.description || ''}
                                onChange={props.setValue}
                                label={props.nodeInfo.parent + ' Description'}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props.nodeInfo.isSdgBased}
                                            onChange={(e) => {
                                                props.setValue({ target: { name: 'isSdgBased', value: e.target.checked } });
                                                console.log(e.target.checked);
                                            }}
                                        />
                                    }
                                    label="SDG Based"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TwitterPicker
                                width={'100%'}
                                color={props.nodeInfo.color ? props.nodeInfo.color : '#EB144C'}
                                onChangeComplete={handleChangeBackgroundColor}
                            />
                        </Grid>
                        {/* <Grid item={true} xs={1} sm={1}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props.nodeInfo.isActivity}
                                            onChange={(e) => {
                                                props.setValue({ target: { name: 'isActivity', value: e.target.checked } });
                                                console.log(e.target.checked);
                                            }}
                                        />
                                    }
                                    label="Is Activity"
                                />
                            </FormGroup>
                        </Grid> */}
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={props.onClose} color="primary">
                        {props.closeText}
                    </Button>
                    <Button disabled={!props.nodeInfo.name} type="submit" variant="contained" color="primary" onClick={props.onOkay}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UpdateDialog;
