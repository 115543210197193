import useLogframeReport from 'hooks/consume_api/query/useLogframeReport'
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Stack, Grid, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import useLogFrameYearlist from 'hooks/consume_api/query/useLogFrameYearlist';
import { width } from '@mui/system';
import Loader from 'ui-component/Loader';
interface YearData {
    year: number;
    data: {
        collection: number;
        target: number;
        cumulative: number;
    };
}

interface DataEntry {
    target: number;
    midTerm: string;
    baseline: number;
    yearData: YearData[];
}

interface AggregateInfo {
    name: string;
    data: DataEntry[];
}

interface MainDataEntry {
    indicator: string;
    aggregateInfo: AggregateInfo[];
}

interface RootData {
    hierarchy: string;
    name: string;
    mainData: MainDataEntry[];
}

const ReportMain = () => {
    const [year, setYear] = useState<any[]>([]);
    const [selectedProject, setSelectedProject] = useState('Rains');
    console.log(selectedProject, "=========")
    const { data: reportData, isFetched } = useLogframeReport(selectedProject);

    const { data: lfYearData } = useLogFrameYearlist();

    const years: any[] = [];
    useEffect(() => {
        if (lfYearData && lfYearData.length) {
            for (let year = Number(lfYearData[0].year_start); year <= Number(lfYearData[0].year_end); year++) {
                years.push(year.toString());
            }
            setYear(years);
        }
    }, [lfYearData]);



    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedProject(event.target.value); // Update state with the selected value
    };

    return (
        <>
            {!isFetched && <Loader />}
            <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12}>
                    <Typography variant="h6">Selected Project</Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="project-select-label">Project</InputLabel>
                        <Select
                            labelId="project-select-label"
                            id="project-select"
                            value={selectedProject}
                            onChange={handleChange}
                        >
                            <MenuItem value="Sacp">Sacp</MenuItem>
                            <MenuItem value="Rains">Rains</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>

                            <TableCell rowSpan={2} sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Results Hierarchy</TableCell>
                            <TableCell colSpan={5} align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                Indicator
                            </TableCell>
                            {year && year.map((item: string, index: number) => (
                                <TableCell colSpan={3} align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Name</TableCell>
                            <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Aggregate</TableCell>
                            <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Baseline</TableCell>
                            <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Mid term</TableCell>
                            <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>End Target</TableCell>
                            {year && year.map((item: string, index: number) => (
                                <>
                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Year Targets                             </TableCell>
                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Year Result</TableCell>
                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Cumulative</TableCell>
                                </>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {reportData && reportData.data.map((item: RootData, index: number) => {
                            const hierarchyRowSpan = item.mainData.reduce((total, main) => total + main.aggregateInfo.length, 0);

                            return item.mainData.map((main, mainIndex) => {
                                return main.aggregateInfo.map((aggregate, aggIndex) => {
                                    const showHierarchy = mainIndex === 0 && aggIndex === 0;
                                    const showIndicator = aggIndex === 0;

                                    return (
                                        <TableRow key={`${index}-${mainIndex}-${aggIndex}`}>

                                            {showHierarchy && (
                                                <TableCell rowSpan={hierarchyRowSpan} sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    {item.hierarchy} - {item.name}
                                                </TableCell>
                                            )}


                                            {showIndicator && (
                                                <TableCell rowSpan={main.aggregateInfo.length} sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    {main.indicator}
                                                </TableCell>
                                            )}


                                            <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                                {aggregate.name}
                                            </TableCell>
                                            <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                                {aggregate.data[0].baseline}
                                            </TableCell>
                                            <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                                {aggregate.data[0].midTerm}
                                            </TableCell>
                                            <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                                {aggregate.data[0].target}
                                            </TableCell>

                                            {year && year.map((_, yearIndex) => (
                                                <React.Fragment key={yearIndex}>
                                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                                        {aggregate.data[0].yearData[yearIndex]?.data.target || 0}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                                        {aggregate.data[0].yearData[yearIndex]?.data.collection || 0}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                                        {aggregate.data[0].yearData[yearIndex]?.data.cumulative || 0}
                                                    </TableCell>
                                                </React.Fragment>
                                            ))}
                                        </TableRow>
                                    );
                                });
                            });
                        })}
                    </TableBody>

                </Table>
            </TableContainer>
        </>

    )
}

export default ReportMain;