import { useQuery } from '@tanstack/react-query';
import { subActivityGain } from '../../../react_query/query-keys';
import { subActivityCountGain } from '../../../services/reactQueryservices';

const useSubActivityCountGain = (subActivity: string, startYear: string, endYear: string) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [subActivityGain, subActivity, startYear, endYear],
        queryFn: () => subActivityCountGain(subActivity, startYear, endYear),
        enabled: true && !!subActivity
    })

    return {
        data,
        isFetching,
        error
    }
}

export default useSubActivityCountGain