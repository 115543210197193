import { useQuery } from '@tanstack/react-query';
import { projectDetailsKey } from '../../../react_query/query-keys';
import { projectDetails } from '../../../services/reactQueryservices';

const useProjectDetails = (instanceId: string) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [projectDetailsKey, instanceId],
        queryFn: () => projectDetails(instanceId),
        enabled: !!instanceId
    })

    return {
        data,
        isFetching,
        error
    }
}

export default useProjectDetails