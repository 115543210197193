import React from 'react';
import { dashboardInput } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';
import { dashboardInputKey } from '../../../react_query/query-keys';

const useDashboardInput = (project: string,
    agency: string,
    division: string,
    district: string,
    upazila: string,
    year: string) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [dashboardInputKey, division, district, upazila, year],
        queryFn: () => dashboardInput(project, agency, division, district, upazila, year)
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };

}

export default useDashboardInput