import React from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import { useTheme } from '@mui/material/styles';
import Badc from './OrganizationWisePlan/Badc/Badc';
import Gain from './OrganizationWisePlan/gain/Gain';
import Fao from './OrganizationWisePlan/fao/Fao';
import Dae from './OrganizationWisePlan/dae/Dae';
import Dam from './OrganizationWisePlan/Dam/Dam';
import Bari from './OrganizationWisePlan/Bari/Bari';
import BadcMain from './OrganizationWisePlan/Badc/BadcMain';
import BariMain from './OrganizationWisePlan/Bari/BariMain';
import DaeMain from './OrganizationWisePlan/dae/DaeMain';
import DamMain from './OrganizationWisePlan/Dam/DamMain';
import FaoMain from './OrganizationWisePlan/fao/FaoMain';
import GainMain from './OrganizationWisePlan/gain/GainMain';

function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// tabs option
const tabsOption = [
    {
        label: 'BADC'
    },
    {
        label: 'BARI'
    },
    {
        label: 'DAE'
    },
    {
        label: 'DAM'
    },
    {
        label: 'FAO'
    },
    {
        label: 'GAIN'
    }
];
const WorkPlanMain = () => {
    const theme = useTheme();
    const [value, setValue] = React.useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    sx={{
                        mb: 3,
                        '& a': {
                            minHeight: 'auto',
                            minWidth: 10,
                            py: 1.5,
                            px: 1,
                            mr: 2.25,
                            color: theme.palette.grey[600],
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        },
                        '& a.Mui-selected': {
                            color: theme.palette.success.main
                        },
                        '& .MuiTabs-indicator': {
                            bottom: 2
                        },
                        '& a > svg': {
                            marginBottom: '0px !important',
                            mr: 1.25
                        }
                    }}
                >
                    {tabsOption.map((tab, index) => (
                        <Tab key={index} component={Link} to="#" label={tab.label} {...a11yProps(index)} />
                    ))}
                </Tabs>

                <TabPanel value={value} index={0}>
                    <BadcMain />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <BariMain />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <DaeMain />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <DamMain />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <FaoMain />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <GainMain />
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                        <ReportingPeriodType />
                    </TabPanel> */}
            </Grid>
        </Grid>
    );
};

export default WorkPlanMain;
