import { useParams } from 'react-router-dom';
import DashboardUI from 'dashboard-ui-renderer';

const Render = () => {
    const dasboardId = useParams().id;

    return (
        <DashboardUI
            baseUrl={`${process.env.REACT_APP_SERVICE_URL}/mSurvey/api/`}
            elementsEndpoint="chart/generateChart"
            dashboardEndpoint="dashboard/get"
            route="def"
            id={dasboardId}
        />
    );
};

export default Render;
