import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import useDisaggregationlist from 'hooks/consume_api/query/useDisaggregationlist';
import { InputLabel, TextField } from '@mui/material';
import { useFormik, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import useBaselineDisaggregationCreate from 'hooks/consume_api/mutation/useBaselineDisaggregationCreate';
import useGetBaselineDisaggregation from 'hooks/consume_api/query/useGetBaselineDisaggregation';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IBaseLineDisaggregation {
    logframeId: string;
    open: boolean;
    handleClose: () => void;
}

const BaselineDisaggregation: React.FC<IBaseLineDisaggregation> = ({ logframeId, open, handleClose }) => {
    const { data, isFetched } = useDisaggregationlist(logframeId);
    const { data: baselineData } = useGetBaselineDisaggregation(logframeId);

    const { mutate } = useBaselineDisaggregationCreate();
    const initialValues = data
        ? data.reduce((values: any, item: any) => {
              values[item.id] = baselineData?.[item.id] ?? '';
              return values;
          }, {})
        : {};

    const validationSchema = Yup.object(
        data
            ? data.reduce((schema: any, item: { id: number }) => {
                  schema[item.id] = Yup.number().typeError('This field must be a number').required('This field is required');
                  return schema;
              }, {})
            : {}
    );

    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{'Base line value for Disaggregation'}</DialogTitle>
                <DialogContent>
                    {isFetched && data ? (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                console.log('Form values:', values);
                                mutate({
                                    logframeId: logframeId,
                                    value: values
                                });
                            }}
                        >
                            {({ handleSubmit, errors, touched }) => (
                                <Form onSubmit={handleSubmit}>
                                    {data.map((item: any, index: number) => (
                                        <div key={index}>
                                            <InputLabel htmlFor={`field-${item.id}`}>{item.item}</InputLabel>
                                            <Field
                                                as={TextField}
                                                name={item.id}
                                                id={`field-${item.id}`}
                                                variant="outlined"
                                                fullWidth
                                                error={touched[item.id] && Boolean(errors[item.id])}
                                                helperText={touched[item.id] && errors[item.id]}
                                            />
                                        </div>
                                    ))}
                                    <Button type="submit" variant="contained" color="primary">
                                        Save
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default BaselineDisaggregation;
