import React, { createContext, useEffect, useReducer } from 'react';
// import config from '../config';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import accountReducer from 'store/accountReducer';
import { LOGIN, LOGOUT } from 'store/actions';

// project imports
import useSnackbar from 'hooks/useSnackbar';
import { useDispatch } from 'react-redux';
import { initialLoginContextProps, KeyedObject } from 'types';
import { JWTContextType, StudentAttributeType } from 'types/auth';
import Loader from 'ui-component/Loader';
import { _deserailize } from 'utils/Deserialize';
import { loginURL, resetPasswordRequestURL, userProfileByKCIdURL, userProfileURL, refreshTokenURL } from 'utils/serverUrls';
import axiosServices from '../utils/axiosServices';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

// constant
const initialState: initialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};
const today = new Date();

const tomorrow = new Date(today);

tomorrow.setDate(today.getDate() + 1);

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    console.log('expiry', decoded.exp);
    return decoded.exp > Date.now() / 1000;
};
const cookies = new Cookies();

export const setSession = (serviceToken?: string | null) => {
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe') as any);

    if (rememberMe && serviceToken) {
        localStorage.setItem('token', serviceToken);
    } else if (!rememberMe && serviceToken) {
        sessionStorage.setItem('token', serviceToken);
        cookies.set('token', serviceToken, { expires: tomorrow });
    } else {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refreshToken');
        cookies.remove('token');
        cookies.remove('refreshToken');
        delete axiosServices.defaults.headers.common.Authorization;
    }
};
const expireTime = 0;
export const setRefreshToken = (refreshToken?: string | null) => {
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe') as any);
    if (refreshToken && rememberMe) {
        localStorage.setItem('refreshToken', refreshToken);
    } else if (!rememberMe && refreshToken) {
        sessionStorage.setItem('refreshToken', refreshToken);
        cookies.set('refreshToken', refreshToken, { expires: tomorrow });
    } else {
        sessionStorage.removeItem('refreshToken');
        localStorage.removeItem('refreshToken');
        cookies.remove('token');
        cookies.remove('refreshToken');
        delete axiosServices.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const reduxDispatch = useDispatch();

    const snackbarMsg = useSnackbar();

    const setUserStateData = async (serviceToken: any) => {
        const decoded: KeyedObject = jwtDecode(serviceToken);
        try {
            const response = await axiosServices.get(`${userProfileByKCIdURL}`, { params: { kcId: decoded.sub } });
            if (response.data) {
                const userResponse = await _deserailize(response.data);

                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: {
                            ...userResponse,
                            ...jwtDecode(serviceToken)
                        }
                    }
                });

                reduxDispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: {
                            ...userResponse,
                            ...decoded
                        }
                    }
                });
            } else {
                console.log('logging out ');
                logout();
            }
        } catch (err) {
            console.log(err);
            snackbarMsg('Failed to login!', 'error');
            logout();
        }
    };

    const getRenewedAccessToken = async () => {
        const refreshToken = sessionStorage.getItem('refreshToken');
        // eslint-disable-next-line
        const params = { refreshToken: refreshToken };
        try {
            const response = await axiosServices.post(refreshTokenURL, params);

            if (response.data?.token) {
                setSession(response.data.token);
                setRefreshToken(response.data.refresh_token.token);
                setUserStateData(response.data.token);
            } else {
                logout();
            }
        } catch (err) {
            console.log('catch');
            logout();
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                const rememberMe = JSON.parse(localStorage.getItem('rememberMe') as any);
                let serviceToken = null;

                if (rememberMe) {
                    serviceToken = localStorage.getItem('token');
                } else {
                    serviceToken = cookies.get('token');
                    // serviceToken = sessionStorage.getItem('token');
                }
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    setUserStateData(serviceToken);
                } else {
                    getRenewedAccessToken();
                }
            } catch (err) {
                logout();
            }
        };

        init();
    }, []);

    // login user in session

    const login = async (username: string, password: string) => {
        try {
            const response = await axiosServices.post(loginURL, { username, password });
            if (response.data.token) {
                setSession(response.data.token);
                setRefreshToken(response.data.refresh_token.token);
                setUserStateData(response.data.token);
            } else {
                snackbarMsg('Invalid Request', 'error');
            }
        } catch (error) {
            snackbarMsg('Failed to login!', 'error');
            console.log(error);
        }
    };

    const register = async (studentData: StudentAttributeType) => {
        const response = await axiosServices.post('users/students/create/', { data: { type: 'User', attributes: studentData } });
        const userData = response.data.data;
        let users: any[] = [
            {
                id: userData?.id,
                email: userData?.attributes?.profile?.email,
                name: userData?.attributes?.profile?.name
            }
        ];

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers!),
                {
                    id: userData?.id,
                    email: userData?.attributes?.profile?.email,
                    name: userData?.attributes?.profile?.name
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);

        dispatch({ type: LOGOUT });
        cookies.remove('token');
        cookies.remove('refreshToken');
    };

    const resetPassword = async (email: string) =>
        axiosServices.post(resetPasswordRequestURL, {
            email,
            url: `${window.location.origin}/new-password`
        });

    const updateProfile = () => { };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

export default JWTContext;
