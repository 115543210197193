import { useQuery } from '@tanstack/react-query';
import { frequenciesKey } from '../../../react_query/query-keys';
import { frequenciesByLogFrame } from '../../../services/reactQueryservices';

const useGetFrequencyByLogframeId = (logFrameId: string) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [frequenciesKey, logFrameId],
        queryFn: () => frequenciesByLogFrame(logFrameId),
        enabled: !!logFrameId
    })

    return {
        data,
        isFetching,
        error
    }
}

export default useGetFrequencyByLogframeId