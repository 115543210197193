import useSubActivityCountBari from 'hooks/consume_api/query/useSubActivityCountBari';
import React, { useEffect } from 'react';
interface props {
    valueText: string;
    startYear: string;
    endYear: string;
    handleAchievement(id: string | number, value: string | number, startYear: string, endYear: string): void;
}

const BariAchievement: React.FC<props> = ({ valueText, startYear, endYear, handleAchievement }) => {
    const { data, isFetching } = useSubActivityCountBari(valueText, startYear, endYear);
    useEffect(() => {
        if (data) {
            handleAchievement(valueText, data.data.total, startYear, endYear);
        }
    }, [data]);

    return (
        <div>
            {isFetching && 'loading..'}
            {data?.data.total}
        </div>
    );
};

export default BariAchievement;
