import React from 'react';
import { dashboardDemonstration } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';
import { demonstrationKey } from '../../../react_query/query-keys';

const useDashboardDemonstration = (project: string, agency: string, division: string, district: string, upazila: string, year: string) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [demonstrationKey, division, district, upazila, year, project, agency],
        queryFn: () => dashboardDemonstration(project, agency, division, district, upazila, year)
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };
}

export default useDashboardDemonstration