import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    agreeAction: () => void;
    dialogTitleText: string;
    dialogContentText: string;
    disagreeText: string;
    agreeText: string;
}

const PromptDialog: React.FC<Props> = ({ open, setOpen, agreeAction, dialogTitleText, dialogContentText, disagreeText, agreeText }) => {
    const theme = useTheme();

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Dialog
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
            >
                <DialogTitle id="alert-dialog-title">{dialogTitleText}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="body2" component="span">
                            {dialogContentText}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pr: 2.5 }}>
                    <Button
                        sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                        onClick={handleClose}
                        color="secondary"
                    >
                        {disagreeText}
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            agreeAction();
                            handleClose();
                        }}
                        autoFocus
                    >
                        {agreeText}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PromptDialog;
