import { useQuery } from '@tanstack/react-query';
import { workPlanListByAgencyAndFiscalYear } from '../../../react_query/query-keys';
import { workPlanListByAgencyAndYear } from '../../../services/reactQueryservices';

const useGetWorkPlanListByYearAndAgency = (agency: string, startYear: string, endYear: string, open: boolean) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [workPlanListByAgencyAndFiscalYear, agency, startYear, endYear],
        queryFn: () => workPlanListByAgencyAndYear(agency, startYear, endYear),
        enabled: open && !!agency && !!endYear && !!startYear
    })
    return {
        data,
        isFetching,
        error
    }

}

export default useGetWorkPlanListByYearAndAgency