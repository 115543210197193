import { useMutation } from '@tanstack/react-query';
import useSnackbar from "../../useSnackbar";
import { memberInstanceDelete } from "../../../services/reactQueryservices";
import { memberInstanceList } from 'react_query/query-keys';
import { client } from '../../../react_query/query-client-configuration';
const useMemberInstanceDelete = () => {
    const snackbar = useSnackbar()
    const { mutate, isSuccess } = useMutation({
        mutationFn: memberInstanceDelete,
        onSettled: (data, error) => {
            if (error) {
                snackbar('Failed to delete', 'error');
            } else {
                client.invalidateQueries({ queryKey: [memberInstanceList] });
                snackbar('successfully deleted');
            }
        }
    });

    return {
        mutate, isSuccess
    }
}

export default useMemberInstanceDelete