import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DisaggregationTable from './DisaggregationTable';
import { defaultCollectionBasic, filterDtypes, filterDvalues, Transition } from './constants';
import { gridSpacing } from 'store/constant';
import SubCard from 'ui-component/cards/SubCard';
import React, { useEffect, useState } from 'react';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import { checkValidNumber } from 'utils/Helpers';
import { useParams } from 'react-router-dom';
import useGetFrequencyByLogframeId from 'hooks/consume_api/query/useGetFrequencyByLogframeId';
import { useLocation } from 'react-router-dom';

// import useSnackbar from 'hooks/useSnackbar';

interface TreeItem {
    label: string;
    id: string;
    parent: string;
    child: TreeItem[] | null;
    value?: string | null;
}

export default function DialogTable(props: any) {
    let param = useParams();



    const [collectionBasic, setCollectionBasic] = useState<typeof defaultCollectionBasic>(defaultCollectionBasic);
    const [refTree, setRefTree] = useState<any>(props.rootTree.data);
    const [year, setYear] = useState<string>(props.rootTree.year);
    const [month, setMonth] = useState<string>(props.rootTree.month);
    const { logframeId } = useParams();
    const location = useLocation();
    const { data: frquencyData } = useGetFrequencyByLogframeId(logframeId ? logframeId : '');
    const hasDataCollection = location.pathname.includes('/data-collection');
    const [input, setInput] = useState<string>("");

    useEffect(() => {
        if (props.rootTree.input) setInput(props.rootTree.input);
    }, [props.rootTree.input])

    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    const monthList = monthNames.map((month) => ({
        value: month,
        label: month.toLowerCase()
    }));

    useEffect(() => {
        setRefTree(props.rootTree.data);
        console.log(' root tree is getting called ', props.rootTree);
        console.log(' props.editMode ', props.editMode);
        if (props.rootTree.total && props.editMode) {
            setCollectionBasic((prev) => {
                return { ...prev, total: props.rootTree.total, year: year, month: month };
            });
        } else {
            setCollectionBasic(defaultCollectionBasic);
        }
    }, [props.rootTree.data]);

    const handleCollectionEntry = (key: string, value: any) => {


        if (checkValidNumber(value) || value === '') {

            if (key === 'collection_period') {
                const result = props.dataEntries.find((d: any) => d.collectionPeriod === value);
                if (result) {
                    setCollectionBasic({ ...collectionBasic, ...result.jsonData.collection_data_entry, [key]: value });
                    setRefTree(result.jsonData.collection_data_entry.data);
                } else {
                    setCollectionBasic({ ...defaultCollectionBasic, [key]: value });
                    setRefTree({});
                }
            } else {
                setCollectionBasic({ ...collectionBasic, [key]: value });
            }
        }
    };

    const handleSubmit = () => {
        const collectionData = {
            ...collectionBasic,
            data: refTree,
            year: year,
            input: input,
            month: month
        };

        console.log(collectionData);
        if (collectionData.total) props.handleSubmit(collectionData);
        else props.showToast('Please add value in the total field', 'error');
    };

    const isFloat = (value: number) => {
        return typeof value === 'number' && !Number.isInteger(value);
    }

    function sumChildValues(data: TreeItem[]) {
        let sum = 0;

        // data.forEach((obj) => {
        //     if (obj.child && obj.child.length > 0) {
        //         sum += obj.child.reduce((acc, child) => {
        //             if (child.value) {
        //                 acc += parseInt(child.value);
        //             }
        //             return acc;
        //         }, 0);
        //     }
        // });

        data.map((item) => {
            if (item.value) {
                if (hasDataCollection && (logframeId === '158' || logframeId === '126' || logframeId === '129' || logframeId === '127' || logframeId === '128' || logframeId === '145' || logframeId === '146' || logframeId === '147' || logframeId === '148' || logframeId === '188' || logframeId === '190' || logframeId === '191' || logframeId === '192' || logframeId === '194' || logframeId === '195' || logframeId === '196' || logframeId === '198' || logframeId === '199'))
                    sum += parseFloat(item.value);
                else
                    sum += parseInt(item.value);
            }
        });


        return isFloat(sum) ? sum.toFixed(2) : sum;
    }

    const handleTreeChange = (key: any, data: any) => {
        const total = sumChildValues(data);
        handleCollectionEntry('total', total);
        const _cloneTree = _.cloneDeep(refTree);
        _cloneTree[key] = data;
        console.log(_cloneTree, "-----------------")
        setRefTree(_cloneTree);
    };

    // const showFrequency = () => {
    //     try {
    //         return props.frequency.setting && props.frequency.setting.option ? true : false;
    //     } catch (ex) {
    //         toastMsg(
    //             'Settings has not been defined for this indicator. please add frequency, units of measurements and other related setti',
    //             'error'
    //         );
    //     }
    // };

    const getBasicInput = (setting: string) => {
        if (setting !== undefined && setting == 'target') {
            console.log('########### inside target ##############', setting);
            return (
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="Total"
                            value={collectionBasic.total}
                            disabled={!props.editMode}
                            onChange={(e: any) => handleCollectionEntry('total', e.target.value)}
                        />
                    </Grid>
                </Grid>
            );
        }
        return (
            <Grid container spacing={gridSpacing}>
                {/* {showFrequency() ? (
                    <Grid item xs={4} md={4}>
                        <Select
                            labelId="frequencyLabel"
                            id="frequency_label"
                            label="Frequency"
                            fullWidth
                            name="collection_period"
                            onChange={(e: any) => handleCollectionEntry('collection_period', e.target.value)}
                        >
                            {props.frequency.setting &&
                                props.frequency.setting.option &&
                                props.frequency.setting.option.map((f: any) => {
                                    return (
                                        <MenuItem value={f.key} key={f.key}>
                                            {f.value}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </Grid>
                ) : null} */}
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        label="Total"
                        value={collectionBasic.total}
                        onChange={(e: any) => handleCollectionEntry('total', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        label="Input"
                        value={collectionBasic.other_input}
                        onChange={(e: any) => handleCollectionEntry('other_input', e.target.value)}
                    />
                </Grid>
            </Grid>
        );
    };

    useEffect(() => {
        setYear(props.rootTree.year);
    }, [props.rootTree.year]);

    useEffect(() => {
        setMonth(props.rootTree.month);
    }, [props.rootTree.month]);
    return (
        <div>
            <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.handleClose} maxWidth={'xl'}>
                <DialogTitle>{'Disaggregation Table'}</DialogTitle>

                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={12}>
                            <SubCard title="">
                                {getBasicInput(props.settings)}
                                {/* {hasDataCollection && frquencyData && frquencyData.frequency === 'Yearly' && (
                                    <>
                                        <Typography sx={{ marginTop: '10px' }}>Input year:</Typography>
                                        <TextField onChange={(e) => setYear(e.target.value)} value={year} />
                                    </>
                                )} */}
                                {hasDataCollection && (logframeId === '158' || logframeId === '126' || logframeId === '129' || logframeId === '127' || logframeId === '128' || logframeId === '145' || logframeId === '146' || logframeId === '147' || logframeId === '148' || logframeId === '188' || logframeId === '190' || logframeId === '191' || logframeId === '192' || logframeId === '194' || logframeId === '195' || logframeId === '196' || logframeId === '198' || logframeId === '199') &&
                                    <TextField value={input} onChange={(e) => setInput(e.target.value)} id="filled-basic" label="input" variant="outlined" sx={{ marginTop: "5px" }} />}
                                {/* {hasDataCollection && frquencyData && frquencyData.frequency === 'Monthly' && (
                                    <>
                                        <Autocomplete
                                            options={monthList}
                                            value={monthList.find((option) => option.value === month) || null}
                                            getOptionLabel={(option) => option.value}
                                            renderInput={(params) => <TextField {...params} label="Select Month" variant="outlined" />}
                                            onChange={(event, newValue) => {
                                                if (newValue) setMonth(newValue.value);
                                            }}
                                            style={{ width: 300, marginTop: '10px' }}
                                        />

                                    </>
                                )} */}
                            </SubCard>
                        </Grid>

                        {props.dGroups.map((dgroup: any, index: number) => (
                            <Grid item xs={12} md={12} key={index}>
                                <SubCard title="Disaggregations">

                                    <DisaggregationTable
                                        input={input}
                                        logFrameId={logframeId}
                                        date={localStorage.getItem("date")}
                                        disaggregationIndex={index}
                                        refTree={refTree}
                                        collectionBasic={collectionBasic}
                                        dTypes={filterDtypes(dgroup, props.dTypes)}
                                        dValues={filterDvalues(dgroup, props.dValues)}
                                        editMode={props.editMode}
                                        handleTreeChange={(data: any) => handleTreeChange(index, data)}
                                    />
                                </SubCard>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>

                <DialogActions>
                    {props.editMode && <Button onClick={handleSubmit}>Submit</Button>}
                    <Button onClick={props.handleClose} sx={{ color: 'red' }}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
