import { useMutation, useQueryClient } from '@tanstack/react-query';
import { projectActivityKey } from 'react_query/query-keys';
import { deleteProject } from 'services/reactQueryservices';
import useSnackbar from 'hooks/useSnackbar';

const useDeleteProject = () => {
    const queryClient = useQueryClient();
    const snackbar = useSnackbar();
    const { mutate, error, isSuccess, isError } = useMutation({

        mutationFn: deleteProject,
        onSettled: (data, error) => {
            if (error) {
                console.log(error);
            }
            else {
                snackbar("Project delete operation successful");
                queryClient.invalidateQueries({
                    queryKey: [projectActivityKey]
                })
            }
        }
    })

    return {
        mutate, error, isSuccess, isError
    }
}

export default useDeleteProject