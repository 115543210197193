import { TextField } from '@mui/material'
import React from 'react'
interface props {
    id: string | number,
    handleUnit(id: string | number, unit: string): void
}
const BadcQuantity: React.FC<props> = ({ id, handleUnit }) => {
    return (
        <TextField
            onChange={(e) => {
                handleUnit(id, e.target.value);
            }}
            id="outlined-basic"
            variant="outlined" />
    )
}

export default BadcQuantity