// project imports
import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Feedback from 'views/Feedback';
import Forms from 'views/Forms';
import FormUpload from 'views/Forms/Upload';
import Profile from 'views/Profile';
import ProjectDetails from 'views/Projects/Details';
import FormProfile from 'views/Projects/Details/Forms/FormProfile';
import ProjectTeamMemberAdd from 'views/Projects/Details/Teams/Add';
import DashboardBuilder from 'views/Dashboard/Builder';
import DashboardRender from 'views/Dashboard/Render';
import LogframeSettings from 'views/LogframeSetting';
import MemberSites from 'views/Projects/Details/Teams/MemberSites';
import SacpRegisters from 'views/Projects/Details/Sacp';
import Role from 'views/Projects/Details/Sacp/Permission/Role';
import PermissionList from 'views/Projects/Details/Sacp/Permission/PermissionList';

// sample page routing
const Projects = Loadable(lazy(() => import('views/Projects')));
const RoleManagement = Loadable(lazy(() => import('views/TeamManagement/RoleManagement')));
const UserManagement = Loadable(lazy(() => import('views/TeamManagement/UserManagement')));
const FormDetails = Loadable(lazy(() => import('views/Projects/Details/Forms/FormProfile/Details')));
const UserFormData = Loadable(lazy(() => import('views/Projects/Details/Forms/FormProfile/UserFormData')));
// const FormSettings = Loadable(lazy(() => import('views/Projects/Details/Forms/FormProfile/Settings')));
const FormSettings = Loadable(lazy(() => import('views/Projects/Details/Forms/Settings')));
const NewSubmission = Loadable(lazy(() => import('views/Projects/Details/Forms/FormProfile/NewSubmission')));
const Organization = Loadable(lazy(() => import('views/TeamManagement/OrgManagement')));
const Notifications = Loadable(lazy(() => import('views/Notifications')));
const Permission = Loadable(lazy(() => import('views/Permission')));
const Category = Loadable(lazy(() => import('views/MasterData/Category')));
const Item = Loadable(lazy(() => import('views/MasterData/Item')));
const DashboardList = Loadable(lazy(() => import('views/Dashboard')));
const Activity = Loadable(lazy(() => import('views/Projects/Details/Activity/Details')));
const SacpGroup = Loadable(lazy(() => import('views/Projects/Details/Sacp/Group/details')));
const SacpEvent = Loadable(lazy(() => import('views/Projects/Details/Sacp/Event/details/Profile')));
const SacpInput = Loadable(lazy(() => import('views/Projects/Details/Sacp/Input/details/Profile')));
const ProjectProfile = Loadable(lazy(() => import('views/Projects/Details/Sacp/ProjectActivity/ProjectProfile')));
const MemberProfile = Loadable(lazy(() => import('views/Projects/Details/Sacp/Member/MemberProfile')));
const Frequency = Loadable(lazy(() => import('views/Configuration/Frequency')));
const UnitOfMeasurement = Loadable(lazy(() => import('views/Configuration/UnitOfMeasureMent')));
const YearMain = Loadable(lazy(() => import('views/Projects/Details/Sacp/YearForLogFrame/YearMain')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),

    children: [
        {
            path: '/',
            element: <Projects />
        },
        {
            path: '/permission/role',
            element: <Role />
        },
        {
            path: '/permission/role/:role_name/:id',
            element: <PermissionList />
        },
        {
            path: '/project/:id',
            element: <ProjectDetails />
        },
        {
            path: '/project/:id?tabIndex=:tabIndex',
            element: <ProjectDetails />
        },
        {
            path: '/project/:id?tabIndex=:tabIndex&registerTab=:registerTab',
            element: <SacpRegisters />
        },
        {
            path: '/project/:id/add-team-member/',
            element: <ProjectTeamMemberAdd />
        },
        {
            path: '/forms/',
            element: <Forms />
        },
        {
            path: '/feedback',
            element: <Feedback />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/roles',
            element: <RoleManagement />
        },
        {
            path: '/organizations',
            element: <Organization />
        },
        {
            path: '/users',
            element: <UserManagement />
        },
        {
            path: '/project/:projectId/form/:formId/:xformId',
            element: <FormProfile />
        },
        {
            path: '/project/:projectId/form/:formId/:xformId/details',
            element: <FormDetails />
        },
        {
            path: '/project/:projectId/form/:formId/data/:userId',
            element: <UserFormData />
        },
        // {
        //     path: '/project/:projectId/form/:formId/settings',
        //     element: <FormSettings />
        // },
        {
            path: '/project/:projectId/form/:formId/:xformId/settings',
            element: <FormSettings />
        },
        {
            path: '/project/:projectId/form/:formId/:xformId/new-submission',
            element: <NewSubmission />
        },
        {
            path: '/project/:projectId/form/:formId/:xformId/edit-submission/:instanceId',
            element: <NewSubmission />
        },
        {
            path: '/projects/:projectId/member/:memberId/sites',
            element: <MemberSites />
        },
        {
            path: '/projects/:projectId/profile/logframe/:logframeId/indicator-settings',
            element: <LogframeSettings.IndicatorSettings />
        },
        {
            path: '/projects/:projectId/profile/logframe/:logframeId/targets',
            element: <LogframeSettings.IndicatorTargets />
        },
        {
            path: '/projects/:projectId/profile/logframe/:logframeId/data-upload',
            element: <LogframeSettings.DataUploadConfig />
        },
        {
            path: '/projects/:projectId/profile/logframe/:logframeId/data-collection',
            element: <LogframeSettings.DataCollection />
        },
        {
            path: '/projects/:projectId/profile/logframe/:logframeId/indicator-profile',
            element: <LogframeSettings.IndicatorProfile />
        },
        {
            path: '/form-upload',
            element: <FormUpload />
        },
        {
            path: '/notifications',
            element: <Notifications />
        },
        {
            path: '/permission',
            element: <Permission />
        },
        {
            path: '/category',
            element: <Category />
        },
        {
            path: '/item/:title/:id',
            element: <Item />
        },
        {
            path: '/dashboards',
            element: <DashboardList />
        },
        {
            path: '/dashboard/create',
            element: <DashboardBuilder />
        },
        {
            path: '/dashboard/:id',
            element: <DashboardRender />
        },
        {
            path: '/activity/:id',
            element: <Activity />
        },
        {
            path: '/project/:projectId/sacp/group/:groupId',
            element: <SacpGroup />
        },
        {
            path: '/project/:projectId/sacp/event/:eventId',
            element: <SacpEvent />
        },
        {
            path: '/project/:projectId/sacp/input/:inputId',
            element: <SacpInput />
        },
        {
            path: '/project/:projectId/sacp/project-activity/profile',
            element: <ProjectProfile />
        },
        {
            path: '/project/:projectId/sacp/member/profile',
            element: <MemberProfile />
        },
        {
            path: '/frequency',
            element: <Frequency />
        },
        {
            path: '/unitOfMeasurement',
            element: <UnitOfMeasurement />
        },
        {
            path: '/year',
            element: <YearMain />
        },
    ]
};

export default MainRoutes;
