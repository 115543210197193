import { useEffect, useState } from 'react';
import { CardData } from '../../../types'; // Update the import path based on your actual types
import { useQuery } from '@tanstack/react-query';
import { cardDataKeys } from '../../../react_query/query-keys'; // Update the import path based on your actual query keys
import { getCardData } from '../../../services/reactQueryservices'; // Update the import path based on your actual service
import { DEFAULT_STALE_TIME } from '../../../utils/Constants';

const useGetCardData = (dependencies: boolean = true) => {
    const [initiateFetchingCardData, setInitiateFetchingCardData] = useState(true);

    const [cardData, setCardData] = useState<CardData | null>(null);

    const result = useQuery<CardData, Error>({
        queryKey: cardDataKeys.all, // Update the query key based on your actual implementation
        queryFn: getCardData, // Update the function based on your actual service
        staleTime: DEFAULT_STALE_TIME,
        enabled: initiateFetchingCardData && dependencies
    });

    useEffect(() => {
        if (result.data && !result.isError) {
            setCardData(result.data);
        }
    }, [result.data]);

    return {
        cardData,
        isCardDataFetching: result.isFetching,
        isCardDataFetched: result.isFetched,
        isCardDataError: result.isError,
        cardDataError: result.error,
        isCardDataSuccess: result.isSuccess,
        ...result,
        setInitiateFetchingCardData
    };
};

export default useGetCardData;
