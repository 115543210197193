import { TextField } from '@mui/material';
import React, { useState } from 'react';
interface props {
    id: number | string;
    value: string;
    handleValue(id: number | string, targetValue: string): void;
}
const ValueEdit: React.FC<props> = ({ id, value, handleValue }) => {
    const [sTarget, setSTarget] = useState<string>(value);

    return (
        <TextField
            value={sTarget}
            onChange={(e) => {
                setSTarget(e.target.value);
                handleValue(id, e.target.value);
            }}
        />
    );
};

export default ValueEdit;
