import { useMutation } from '@tanstack/react-query';
import { fiscalYearCreate } from 'services/reactQueryservices';
import { fiscalYearList } from 'react_query/query-keys';
import { client } from '../../../react_query/query-client-configuration';
const useFiscalYearCreate = () => {
    const { mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: fiscalYearCreate,
        onSettled: (data, error) => {
            if (error) {
            } else {
                client.invalidateQueries({ queryKey: [fiscalYearList] });
            }
        }
    });
    return {
        mutate,
        error,
        isSuccess,
        isError,
        isLoading
    };
};

export default useFiscalYearCreate;
