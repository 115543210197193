import {
    Grid, Typography,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
} from '@mui/material';
import usePermission from 'hooks/consume_api/query/usePermission';
import React from 'react'
import { useParams } from 'react-router-dom';
import Loader from 'ui-component/Loader';
import Switch from '@mui/material/Switch';
import useChangePermission from 'hooks/consume_api/mutation/useChangePermission';


interface IActivity {
    action_name: string,
    module_action_role_id: number,
    permission: boolean
}
interface IPermission {
    module_name: string,
    activity: IActivity[]
}

const PermissionList: React.FC = () => {
    const { id: roleId, role_name } = useParams();
    const { data, isFetching } = usePermission(roleId ? Number(roleId) : 0);
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const { mutate } = useChangePermission()

    const handleChange = (id: number, permission: boolean) => {
        mutate({ id, permission: !permission });
    }

    return (
        <Grid sx={{ backgroundColor: "white", borderRadius: "5px", minHeight: "80vh" }}>
            <Typography variant='h3' sx={{ paddingTop: "20px", marginLeft: "10px", fontWeight: "normal" }}>Permission List for {role_name}</Typography>
            {isFetching && <Loader />}
            <TableContainer component={Paper}>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">#</TableCell>
                            <TableCell align="left">Module Name</TableCell>
                            <TableCell align="left">View</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.data && data?.data.map((item: IPermission, index: number) => (
                            <TableRow key={index}>
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left">{item.module_name}</TableCell>
                                {item.activity.map((i: IActivity) => (
                                    <TableCell align="left"><Switch onChange={() => handleChange(i.module_action_role_id, i.permission)} {...label} checked={!!i.permission} /></TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
        </Grid>
    )
}

export default PermissionList