import React from 'react';
import { infrastructureList } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';
import { infrastructureListKey } from '../../../react_query/query-keys';

const useInfrastructureList = (tag: string) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [infrastructureListKey, tag],
        queryFn: () => infrastructureList(tag)
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };
}

export default useInfrastructureList