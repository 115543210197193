import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getProjectActivityList } from 'services/reactQueryservices';
import { projectActivityKey } from 'react_query/query-keys';

const useGetProjectActivityList = (page: number, row: number, search: string) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [projectActivityKey, page, row, search],
        queryFn: () => getProjectActivityList(page, row, search)
    })

    return {
        data, isFetching, isFetched, isError, error
    }
}

export default useGetProjectActivityList
