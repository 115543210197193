import { useQuery } from '@tanstack/react-query';
import { subActivityBadc } from '../../../react_query/query-keys';
import { subActivityCountBadc } from '../../../services/reactQueryservices';

const useSubActivityCountBadc = (subActivity: string) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [subActivityBadc, subActivity],
        queryFn: () => subActivityCountBadc(subActivity),
        enabled: true && !!subActivity
    })

    return {
        data,
        isFetching,
        error
    }
}

export default useSubActivityCountBadc