import React, { useEffect, useState } from 'react'
type target = {
    startYear: string,
    endYear: string,
    id: string | number,
    target: string
}
type achievement = {
    id: string | number,
    value: string | number,
    startYear: string,
    endYear: string
}

interface props {
    target: target[],
    achievement: achievement[],
    startYear: string,
    endYear: string,
    id: string | number,
}

const GainParcent: React.FC<props> = ({ target, startYear, endYear, id, achievement }) => {
    const [parcent, setParcent] = useState<number>(0);
    useEffect(() => {
        if (target && achievement) {
            const targetIndex = target.findIndex((item) => item.id === id && item.startYear === startYear && item.endYear === endYear)
            if (targetIndex !== -1) {
                const singleTarget = target[targetIndex];
                const achievementIndex = achievement.findIndex((item) => item.id === singleTarget.id && item.startYear === startYear && item.endYear === endYear);
                const per = Number(((Number(achievement[achievementIndex].value) / Number(singleTarget.target)) * 100).toFixed(2));
                setParcent(per);
            }
        }
    }, [target])
    return (
        <div>{parcent}</div>
    )
}

export default GainParcent;