import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    DialogContent,
    Box,
    Dialog,
    Stack
} from '@mui/material';
import { bariData } from '../../SubActivity';
import FiscalYearPopUp from '../../FiscalYearPopUp';
import BariQuantity from './BariQuantity';
import BariCumulitive from './BariCumulitive';
import BariProgressUptoDate from './BariProgressUptoDate';
import BariTarget from './BariTarget';
import BariAchievement from './BariAchievement';
import BariPercent from './BariPercent';
import useWorkPlanMainCreatetion from 'hooks/consume_api/mutation/useWorkPlanMainCreatetion';
import useWorkPlanFiscalYearCreation from 'hooks/consume_api/mutation/useWorkPlanFiscalYearCreation';
import useWorkPlanDelete from 'hooks/consume_api/mutation/useWorkPlanDelete';
import useFiscalYearCreate from 'hooks/consume_api/mutation/useFiscalYearCreate';
import useSnackbar from 'hooks/useSnackbar';
interface props {
    pOpen: boolean;
    pHandleClose: () => void;
}

const Bari: React.FC<props> = ({ pOpen, pHandleClose }) => {
    const snackbar = useSnackbar()

    const [open, setOpen] = useState<boolean>(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const [startYear, setStartYear] = useState<string>('');
    const [endYear, setEndYear] = useState<string>('');

    const [fisCalYear, setFiscalYear] = useState<{ startYear: string; endYear: string }[]>([]);
    const [unit, setUnit] = useState<{ id: string | number; unit: string }[]>([]);
    const [cumulative, setCumulative] = useState<{ id: string; cumulativeValue: string }[]>([]);
    const [target, setTarget] = useState<{ startYear: string; endYear: string; id: string | number; target: string }[]>([]);
    const [progressUptoDate, setProgressUptoDate] = useState<{ id: string; uptoDate: string }[]>([]);
    const [achievement, setAchievement] = useState<{ id: string | number; value: string | number; startYear: string; endYear: string }[]>(
        []
    );

    const { mutate: worPlanMain } = useWorkPlanMainCreatetion();
    const { mutate: workPlanFiscalYear } = useWorkPlanFiscalYearCreation();
    const { mutate: fiscalYearCreate } = useFiscalYearCreate();

    const handleAchievement = (id: string | number, value: string | number, startYear: string, endYear: string) => {
        setAchievement((prev) => [
            ...prev,
            {
                id,
                value,
                startYear,
                endYear
            }
        ]);
    };

    const handleTarget = (startYear: string, endYear: string, id: string | number, target: string) => {
        setTarget((prev) => {
            const existingIndex = prev.findIndex((item) => item.id === id && item.startYear === startYear && item.endYear === endYear);
            if (existingIndex !== -1) {
                prev[existingIndex] = { startYear, endYear, id, target };
            } else {
                prev.push({ startYear, endYear, id, target });
            }
            return [...prev];
        });
    };

    const handleProgressUptoDate = (id: string, progressUptoDate: string) => {
        setProgressUptoDate((prev) => {
            const existingIndex = prev.findIndex((item) => item.id === id);
            if (existingIndex !== -1) {
                prev[existingIndex] = { id, uptoDate: progressUptoDate };
            } else {
                prev.push({ id, uptoDate: progressUptoDate });
            }
            return [...prev];
        });
    };

    const handleUnit = (id: string | number, unit: string) => {
        setUnit((prev) => {
            const existingIndex = prev.findIndex((item) => item.id === id);
            if (existingIndex !== -1) {
                prev[existingIndex] = { id, unit };
            } else {
                prev.push({ id, unit });
            }
            return [...prev];
        });
    };

    const yearAssign = (startYear: string, endYear: string) => {
        setStartYear(startYear ? startYear : '');
        setEndYear(endYear ? endYear : '');
    };

    const handleCumulative = (id: string, cumulativeValue: string) => {
        setCumulative((prev) => {
            const existingIndex = prev.findIndex((item) => item.id === id);
            if (existingIndex !== -1) {
                prev[existingIndex] = { id, cumulativeValue };
            } else {
                prev.push({ id, cumulativeValue });
            }
            return [...prev];
        });
    };

    useEffect(() => {
        if (startYear && endYear) {
            const existingFiscalYear = fisCalYear.find((fy) => fy.startYear === startYear && fy.endYear === endYear);
            if (!existingFiscalYear) {
                setFiscalYear((prevFiscalYears) => [...prevFiscalYears, { startYear: startYear, endYear: endYear }]);
            }
        }
    }, [startYear, endYear]);

    const handlePopUp = () => {
        handleOpen();
    };

    const handleSubmit = () => {
        if (fisCalYear.length) {
            console.log(fisCalYear, "=========");
            fiscalYearCreate({
                agency: '2',
                jsonData: fisCalYear
            });

            let mainData: { id: string; projectName: string; unit: string; quantity: string }[] = [];
            bariData.map((item) => {
                const quantityIndex = unit.findIndex((a) => a.id === item.valueText);
                const progressIndex = progressUptoDate.findIndex((a) => a.id === item.valueText);
                const cumulativeIndex = cumulative.findIndex((a) => a.id === item.valueText);

                const mainJson = {
                    id: item.valueText,
                    projectName: item.projectName,
                    unit: item.unit,
                    quantity: quantityIndex === -1 ? '0' : unit[quantityIndex].unit,
                    progressDate: progressIndex === -1 ? '0' : progressUptoDate[progressIndex].uptoDate,
                    cumulativeAchievement: cumulativeIndex === -1 ? '0' : cumulative[cumulativeIndex].cumulativeValue
                };
                worPlanMain({
                    agency: '2',
                    json: mainJson
                });
                mainData.push(mainJson);
            });

            let fiscalYearData: {
                subActivity: string | number;
                yearStart: string;
                yearEnd: string;
                target: string;
                achievement: string | number;
                parcent: string;
            }[] = [];

            achievement.map((item) => {
                const targetIndex = target.findIndex((t) => t.id === item.id && t.startYear === item.startYear && t.endYear === item.endYear);
                const currentTarget = targetIndex === -1 ? '0' : target[targetIndex].target;

                const calculatedPercent = (Number(item.value) / Number(currentTarget)) * 100;
                const currentPercent = isNaN(calculatedPercent) || !isFinite(calculatedPercent) ? '0' : calculatedPercent.toString();

                let fiscalYearJson = {
                    agency: '2',
                    subActivity: item.id,
                    yearStart: item.startYear,
                    yearEnd: item.endYear,
                    target: currentTarget,
                    achievement: item.value,
                    parcent: currentPercent
                };
                workPlanFiscalYear(fiscalYearJson);
                fiscalYearData.push(fiscalYearJson);
            });
            pHandleClose();
        } else {
            snackbar('Add fiscal year data', 'error');
        }
    };
    return (
        <>
            <Dialog
                open={pOpen}
                onClose={pHandleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
            >
                <DialogContent>
                    <Button onClick={handlePopUp} variant="contained">
                        Add fiscal year
                    </Button>
                    <TableContainer sx={{ marginTop: '10px' }} component={Paper}>
                        <Box sx={{ height: 400, overflowY: 'auto' }}>
                            <Table stickyHeader sx={{ width: '100%' }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">#</TableCell>
                                        <TableCell align="left">Sub-Activity</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                            Project Name
                                        </TableCell>
                                        <TableCell align="left">Unit</TableCell>
                                        <TableCell align="left">Quantity</TableCell>

                                        {fisCalYear &&
                                            fisCalYear.map((item, index) => (
                                                <React.Fragment key={`fiscal-details-${index}`}>
                                                    <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                                        {item.startYear} - {item.endYear} (Target)
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                                        {' '}
                                                        {item.startYear} - {item.endYear} (Achievement)
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                                        {item.startYear} - {item.endYear} (%)
                                                    </TableCell>
                                                </React.Fragment>
                                            ))}
                                        <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                            Cumulative Achievement
                                        </TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                            Progress upto date
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bariData.map((dateItem, dIndex) => (
                                        <TableRow key={dIndex}>
                                            <TableCell align="left">{dIndex + 1}</TableCell>
                                            <TableCell align="left">{dateItem.label}</TableCell>
                                            <TableCell align="left">{dateItem.projectName}</TableCell>
                                            <TableCell align="left">{dateItem.unit}</TableCell>
                                            <TableCell align="left">
                                                <BariQuantity id={dateItem.valueText} handleUnit={handleUnit} />
                                            </TableCell>
                                            {fisCalYear &&
                                                fisCalYear.map((fitem, findex) => (
                                                    <React.Fragment key={`fiscal-detailss-${findex}`}>
                                                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                                            <BariTarget
                                                                id={dateItem.valueText}
                                                                startYear={fitem.startYear}
                                                                endYear={fitem.endYear}
                                                                handleTarget={handleTarget}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                                            <BariAchievement
                                                                valueText={dateItem.valueText}
                                                                handleAchievement={handleAchievement}
                                                                startYear={fitem.startYear}
                                                                endYear={fitem.endYear}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                                            <BariPercent
                                                                achievement={achievement}
                                                                endYear={fitem.endYear}
                                                                startYear={fitem.startYear}
                                                                target={target}
                                                                id={dateItem.valueText}
                                                            />
                                                        </TableCell>
                                                    </React.Fragment>
                                                ))}
                                            <TableCell align="left">
                                                <BariCumulitive id={dateItem.valueText} handleCumative={handleCumulative} />
                                            </TableCell>
                                            <TableCell align="left">
                                                <BariProgressUptoDate
                                                    id={dateItem.valueText}
                                                    handleProgressUptoDate={handleProgressUptoDate}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </TableContainer>
                    <Stack direction="row" justifyContent="space-between" marginTop="10px">
                        <Button onClick={handleSubmit} variant="contained">
                            Submits
                        </Button>
                        <Button onClick={() => pHandleClose()} variant="contained" color="error">
                            Close
                        </Button>
                    </Stack>

                    <FiscalYearPopUp open={open} handleClose={handleClose} yearAssign={yearAssign} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Bari;
