export const project = [
    {
        label: 'SACP',
        id: '1'
    },
    {
        label: 'RAINS',
        id: '2'
    }
];

export const agency = [
    {
        label: 'BARI',
        id: '1'
    },
    {
        label: 'DAE',
        id: '2'
    },
    {
        label: 'DAM',
        id: '3'
    },
    {
        label: 'FAO',
        id: '4'
    },
    {
        label: 'GAIN',
        id: '5'
    }
];
