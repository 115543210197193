// import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props: any) {
  const getName = () => {
    return props.nodeProps.node === undefined ? "" : props.nodeProps.node.title;
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onNoClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this node
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {getName()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onYesClick} sx={{ color: 'red' }}>Yes</Button>
          <Button onClick={props.onNoClick} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div >

  );
}
