import { Grid, TableBody, TextField } from '@mui/material';
import MaUTable from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useGetOutReachData from 'hooks/consume_api/query/useGetOutReachData';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getOutReachData } from 'services/reactQueryservices';
import { checkValidNumber } from 'utils/Helpers';

const style: any = {
    textAlign: 'center',
    border: '1px solid lightgrey'
};

const DisaggregationTable = (props: any) => {
    const [headerRows, setHeaderRows] = useState<any>([]);
    const [leafNodes, setLeafNodes] = useState<any>([]);
    const [leafNodeValues, setLeafNodeValues] = useState<any>([]);
    const [tree, setTree] = useState<any>(null);
    const [disAggregateData, setDisAggregateData] = useState<any[]>([]);

    const location = useLocation();

    const isDataCollection = location.pathname.includes('data-collection');

    useEffect(() => {
        const a = async () => {
            if (props.date.length === 4 && isDataCollection && (props.logFrameId === '158' || props.logFrameId === '126' || props.logFrameId === '129' || props.logFrameId === '127' || props.logFrameId === '128' || props.logFrameId === '145' || props.logFrameId === '146' || props.logFrameId === '147' || props.logFrameId === '148' || props.logFrameId === '188' || props.logFrameId === '190' || props.logFrameId === '191' || props.logFrameId === '192' || props.logFrameId === '194' || props.logFrameId === '195' || props.logFrameId === '196' || props.logFrameId === '198' || props.logFrameId === '199')) {
                const fetchedData: any[] = [];
                const allData: any[] = [];
                for (let i = 0; i < props.dValues.length; i++) {
                    console.log(props.dValues[i], '-----------')
                    const data = await getOutReachData(props.dValues[i].id, `${props.date}`);
                    const disAggregateSingleData = props.dValues[i];
                    disAggregateSingleData.value = (Number(data?.data.value) * Number(props.input ? props.input : 1)) / 100;
                    allData.push(disAggregateSingleData);
                    fetchedData.push((Number(data?.data.value) * Number(props.input ? props.input : 1)) / 100);

                }
                props.handleTreeChange(allData);
                setDisAggregateData(fetchedData);
            }

        }
        a();
    }, [props.date, props.input])


    const prepareData = () => {
        if (headerRows.length > 0 || props.dTypes.length == 0) return;
        const dTypes: any = JSON.parse(JSON.stringify(props.dTypes));

        for (const type of dTypes as any) {
            for (const dValue of props.dValues as any) {
                if (type.id == dValue.parent) {
                    if (type.value) {
                        type.value.push(dValue);
                    } else {
                        type.value = [dValue];
                    }
                }
            }
        }
        console.log({ dTypes });

        // ---------------------- || preparing the tree, adding every child to its parent. || ---------------------

        const data = JSON.parse(JSON.stringify(dTypes));
        console.log(data, "-----------ddd---------")

        const makeTree = (_data: any, index: any): any => {
            if (index === _data.length) return null;
            const item = [];
            for (let i = 0; i < _data[index]?.value?.length; i += 1) {
                const ob = { ..._data[index].value[i] };
                ob.child = makeTree(_data, index + 1);
                item.push(ob);
            }
            return item;
        };
        const result = makeTree(data, 0);
        setTree(result);
        console.log('matrix result: ', result);

        // --------------------------------|| Collection leaf nodes, in order to save leaf value || ----------------------------

        const _leafNodes: any = [];
        const findLeafNodes = (nodes: any, path: any): any => {
            if (nodes === null) return null;
            for (let i = 0; i < nodes.length; i += 1) {
                if (findLeafNodes(nodes[i].child, `${path},${i}`) === null) {
                    _leafNodes.push(`${path}, ${i}`);
                }
            }
        };
        findLeafNodes(result, '');
        console.log('leaf nodes ***', _leafNodes);
        setLeafNodes(_leafNodes);

        // ---------------------- || find depth, it is must to span the column, it calculates how much span does a cell need || ----------------------------

        const findDepth = (_data: any) => {
            if (!_data.child) return 0;
            let sum = 0;

            for (const d of _data.child) sum += findDepth(d);

            if (sum == 0) return _data.child.length;
            return sum;
        };

        // ---------------------- || preparing the matrix. giving the tree a table view. || ---------------------

        const hr: any = [];
        const keys = _.range(100, 1000);
        let cnt: number = 0;
        const makeMatrix = (_data: any) => {
            let child: any = [];
            const cell: any = [];
            console.log('data :::: ', _data);

            for (const r of _data) {
                cnt += 1;
                cell.push(
                    <TableCell key={keys[cnt]} style={style} colSpan={findDepth(r)}>
                        {' '}
                        {r.label}{' '}
                    </TableCell>
                );
                if (r.child) {
                    child = child.concat(r.child);
                }
            }
            hr.push(<TableRow key={_data.id}> {cell} </TableRow>);
            console.log({ child });
            if (child.length > 0) makeMatrix(child);
            // return;
        };
        console.log('result ===>>> ', result);

        makeMatrix(result);
        setHeaderRows(hr);
    };

    useEffect(() => {
        prepareData();
    }, []);


    useEffect(() => {
        console.log(' ----> nice, i am getting called ', props.refTree);
        console.log('disaggregationIndex: ', props.disaggregationIndex);
        console.log('the tree', props.refTree[props.disaggregationIndex]);

        if (!props.refTree[props.disaggregationIndex]) {
            setLeafNodeValues([]);
            return;
        }

        const getNodeValue = (path: any, _tree: any) => {
            // console.log('path:  ', path, _tree);
            path = path
                .substring(1)
                .split(',')
                .map((i: any) => parseInt(i, 10));
            let ref = _.cloneDeep(_tree);
            let theNode;
            for (let i = 0; i < path.length; i += 1) {
                theNode = ref[path[i]];
                ref = theNode.child;
            }
            return theNode.value || '';
        };

        const _leafNodeValues = [];
        for (let i = 0; i < leafNodes.length; i += 1) {
            _leafNodeValues.push({
                leaf: leafNodes[i],
                value: getNodeValue(leafNodes[i], props.refTree[props.disaggregationIndex])
            });
            console.log();
        }

        console.log('leafNode values: ', _leafNodeValues);
        setLeafNodeValues(_leafNodeValues);
    }, [props.refTree, leafNodes]);

    // ---------------|| before changing anything in this method read the below comment ||--------------------

    const handleLeafValue = (e: any) => {

        if (checkValidNumber(e.target.value)) {

            // console.log(props.refTree[props.disaggregationIndex]);
            const path = e.target.name
                .substring(1)
                .split(',')
                .map((i: any) => parseInt(i, 10));
            console.log(path, e.target.name, e.target.value);

            /**  do not remove these varibales 'ref' 'theTree' and 'theNode'.
            these were declared on a purpose.
            theTree holds the reference of the full tree.
            reference of the 'ref' and 'theNode' variable changes in every loop.
            in last iteration 'theNode' holds that node we are looking for in the tree.
            now we set the value of that node. important thing is to note that 'theNode' 
            is the subReference of the 'theTree'. if we update 'theNode' variable. 
            the changes will reflect in 'theTree' variable.
        */
            const theTree = _.cloneDeep(props.refTree[props.disaggregationIndex] || tree);

            let ref = theTree;
            let theNode;
            for (let i = 0; i < path.length; i += 1) {
                theNode = ref[path[i]];
                ref = theNode.child;
            }
            theNode.value = e.target.value;
            console.log(theTree, "-------the tree-----------")
            props.handleTreeChange(theTree);
        }
    };
    function getNumbersFromString(str: string) {
        const numbers = str.match(/\d+/g);
        return numbers ? numbers.join("") : "";
    }

    const getLeafValues = (leaf: any) => {
        // work for only out put start

        const disAggregation = props.dValues[Number(getNumbersFromString(leaf))];

        //work for only out put end
        const leafNode = leafNodeValues.find((l: any) => l.leaf == leaf);
        if (leafNode) return leafNode.value;
        return '';
    };

    const getDisData = (leaf: any) => {
        return disAggregateData[Number(getNumbersFromString(leaf))];
    }



    return (
        <Grid container style={{ height: '250px', overflowX: 'scroll', overflowY: 'hidden' }}>
            <MaUTable>

                <TableHead>{headerRows}</TableHead>
                <TableBody>
                    <TableRow>
                        {leafNodes.map((leaf: any, index: number) => {
                            return (
                                <TableCell key={index} style={style}>
                                    <TextField
                                        name={leaf}
                                        value={(props.logFrameId === '158' || props.logFrameId === '126' || props.logFrameId === '129' || props.logFrameId === '127' || props.logFrameId === '128' || props.logFrameId === '145' || props.logFrameId === '146' || props.logFrameId === '147' || props.logFrameId === '148' || props.logFrameId === '188' || props.logFrameId === '190' || props.logFrameId === '191' || props.logFrameId === '192' || props.logFrameId === '194' || props.logFrameId === '195' || props.logFrameId === '196' || props.logFrameId === '198' || props.logFrameId === '199') ? getDisData(leaf) : getLeafValues(leaf)}
                                        variant="standard"
                                        onChange={handleLeafValue}
                                        disabled={!props.editMode}
                                    />
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableBody>
            </MaUTable>
        </Grid>
    );
};
export default DisaggregationTable;
