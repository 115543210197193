import { useMutation } from '@tanstack/react-query';
import { workPlanDelete } from 'services/reactQueryservices';

const useWorkPlanDelete = () => {
    const { mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: workPlanDelete,
        onSettled: (data, error) => {}
    });
    return {
        mutate,
        error,
        isSuccess,
        isError,
        isLoading
    };
};

export default useWorkPlanDelete;
