import { getMemberListURL, getGroupListURL } from 'utils/serverUrls';
import axiosServices from 'utils/axiosServices';
import { ListPayloadType } from 'types/sacp';

export const getMemberList = async (payload: any) => {
    const response = await axiosServices.post(`${getMemberListURL}`, payload);
    return response?.data;
};

export const getGroupList = async (payload: ListPayloadType) => {
    const response = await axiosServices.post(`${getGroupListURL}`, payload);
    return response?.data;
};

export const deleteInstance = async (payload: any) => {
    // const response = await axiosServices.post(`${getGroupListURL}`, payload);
    // return response?.data;
};
