import { roleList } from 'services/reactQueryservices';
import { roleListKey } from '../../../react_query/query-keys';
import { useQuery } from '@tanstack/react-query';

const useRoleList = () => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [roleListKey],
        queryFn: () => roleList(),
        staleTime: 5 * 60 * 1000
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };
}

export default useRoleList