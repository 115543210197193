import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { userPermission } from 'services/reactQueryservices';

interface IModuleActionRole {
    action_id: string;
    action_name: string;
    module_id: string;
    module_name: string;
    permission: boolean;
    role_id: string;
}
const useUserPermission = () => {
    const [actionData, setActionData] = useState<IModuleActionRole[]>([]);
    const { mutate, isSuccess } = useMutation({
        mutationFn: userPermission,
        onSettled: (data, error) => {
            if (error) {

            } else {
                setActionData(data?.data.data);
            }
        }
    });
    return {
        mutate,
        actionData
    }
}

export default useUserPermission