import useSubActivityCountBadc from 'hooks/consume_api/query/useSubActivityCountBadc'
import React, { useEffect } from 'react';

interface props {
    valueText: string,
    startYear: string,
    endYear: string
    handleAchievement(id: string | number, value: string | number, startYear: string, endYear: string): void
}
const BadcAchievement: React.FC<props> = ({ valueText, startYear, endYear, handleAchievement }) => {
    const { data } = useSubActivityCountBadc(valueText);
    useEffect(() => {
        if (data) {
            handleAchievement(valueText, data.data.total, startYear, endYear);
        }
    }, [data])
    return (
        <div>{data?.data.total}</div>
    )
}

export default BadcAchievement