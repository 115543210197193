import { TextField } from '@mui/material';
import React from 'react';
interface props {
    id: string;
    handleCumative(id: string, cumulative: string): void;
}

const GainCumulative: React.FC<props> = ({ id, handleCumative }) => {
    return (
        <TextField
            onChange={(e) => {
                handleCumative(id, e.target.value);
            }}
            id="outlined-basic"
            variant="outlined"
        />
    );
};

export default GainCumulative;
