// material-ui
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import MainCard, { MainCardProps } from 'ui-component/cards/MainCard';

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //

const AuthCardWrapper = ({ children, ...other }: MainCardProps) => {
    const theme = useTheme();
    return (
        <MainCard
            sx={{
                maxWidth: { xs: 600 },
                margin: { xs: 2.5, md: 3 },
                '& > *': {
                    flexGrow: 1,
                    flexBasis: '50%'
                },
                border: '1px solid #FFF'
            }}
            content={false}
            {...other}
        >
            <Grid container>
                {/* <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ background: theme.palette.primary.main, minHeight: '75vh' }}>
                        <Typography sx={{ color: '#FFF', fontWeight: 600, fontSize: 20, textAlign: 'center', paddingTop: 4 }}>
                            Try mSurvey Free for 30 Days
                        </Typography>
                        <Typography sx={{ marginTop: 3, padding: 2, color: '#FFF', fontSize: 15, textAlign: 'justify' }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                            laborum.
                        </Typography>
                        <Typography sx={{ padding: 2, color: '#FFF', fontSize: 15, textAlign: 'justify' }}>
                            1. Sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit.
                        </Typography>
                        <Typography sx={{ padding: 2, color: '#FFF', fontSize: 15, textAlign: 'justify' }}>
                            2. Wuis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in .
                        </Typography>
                        <Typography sx={{ padding: 2, color: '#FFF', fontSize: 15, textAlign: 'justify' }}>
                            3. Excepteur sint nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                            dolor in .
                        </Typography>
                    </Box>
                </Grid> */}
                <Grid item xs={12}>
                    <Box sx={{ p: { xs: 2, sm: 3, xl: 5 }, height: '100%' }}>{children}</Box>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default AuthCardWrapper;
