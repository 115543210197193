import {
    Grid,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
} from '@mui/material';
import useRoleList from 'hooks/consume_api/query/useRoleList'
import React from 'react'
import Loader from 'ui-component/Loader';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
interface IRole {
    id: number,
    name: string
}

const Role: React.FC = () => {
    const { data, isFetching } = useRoleList();
    const navigate = useNavigate();

    const handleClick = (role_name: string, id: number) => {
        navigate(`/permission/role/${role_name}/${id}`)
    }

    return (
        <Grid sx={{ backgroundColor: "white", borderRadius: "5px" }}>
            {isFetching && <Loader />}
            <TableContainer component={Paper}>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">#</TableCell>
                            <TableCell align="left">name</TableCell>
                            <TableCell align="left">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.data && data?.data.map((item: IRole, index: number) => (
                            <TableRow key={index}>
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left">{item.name}</TableCell>
                                <TableCell sx={{ cursor: "pointer" }} align="left"><EditIcon onClick={() => handleClick(item.name, item.id)} /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default Role