import { useMutation } from '@tanstack/react-query';
import { client } from '../../../react_query/query-client-configuration';
import { permissionListKey } from '../../../react_query/query-keys';
import useSnackbar from '../../useSnackbar';
import { permissionChange } from '../../../services/reactQueryservices';

const useChangePermission = () => {
    const snackbar = useSnackbar();
    const { mutateAsync, mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: permissionChange,
        onSettled: (data, error) => {
            if (error) {
                snackbar('Failed to change permission', 'error');
            } else {
                client.invalidateQueries({ queryKey: [permissionListKey] });
                snackbar('Permission updated successfully');
            }
        }
    });
    return {
        mutate, isSuccess
    }
}

export default useChangePermission