import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { UseMutateFunction } from '@tanstack/react-query';

interface IModalProps {
    open: boolean,
    handleClose: () => void
    mutate: UseMutateFunction<any, any, any, any>;
    id: number
}

const GroupDeleteModal: React.FC<IModalProps> = ({ open, handleClose, id, mutate }) => {
    const handleConfirm = () => {
        handleClose();
        mutate(id);
    }
    return (
        <React.Fragment>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {" Do you want to delete this group ?"}
                </DialogTitle>

                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={handleConfirm} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default GroupDeleteModal