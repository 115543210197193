import { useQuery } from '@tanstack/react-query';
import { logframeYear } from 'services/reactQueryservices';
import { logframeYearKey } from 'react_query/query-keys';

const useLogFrameYearlist = () => {
    const { data, isFetching, error } = useQuery({
        queryKey: [logframeYearKey],
        queryFn: () => logframeYear()
    });

    return {
        data,
        isFetching,
        error
    };
}

export default useLogFrameYearlist;