import { validationListBySubActivity } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';
import { validationListBySubActivityKey } from '../../../react_query/query-keys';

const useValidationListBySubActivity = (sub_activity: string) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [validationListBySubActivityKey, sub_activity],
        queryFn: () => validationListBySubActivity(sub_activity),
        staleTime: 1000 * 60 * 5,
        enabled: !!sub_activity
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };
}

export default useValidationListBySubActivity