import React from 'react'
import { dashboardTraining } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';
import { dashboardTrainingKey } from '../../../react_query/query-keys';
const useDashboardTraining = (division: string, district: string, upazila: string, union: string, year: string, project: string, agency: string) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [dashboardTrainingKey, division, district, upazila, union, year, project, agency],
        queryFn: () => dashboardTraining(division, district, upazila, union, year, project, agency)
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };
}

export default useDashboardTraining;