import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useGetFiscalYearList from 'hooks/consume_api/query/useGetFiscalYearList';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Dam from './Dam';
import DamDetailsByFiscalYear from './DamDetailsByFiscalYear';
import DamDetailsEdit from './DamDetailsEdit';

const DamMain: React.FC = () => {
    const { data, isFetching } = useGetFiscalYearList('4');

    const [viewModal, setViewModal] = useState<boolean>(false);
    const [createModal, setCreateModal] = useState<boolean>(false);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [startYear, setStartYear] = useState<string>('');
    const [endYear, setEndYear] = useState<string>('');

    const handleViewModalOpen = () => setViewModal(true);
    const handleViewModalClose = () => setViewModal(false);

    const handleCreateModalOpen = () => setCreateModal(true);
    const handleCreateModalClose = () => setCreateModal(false);

    const handleEditModalOpen = () => setEditModal(true);
    const handleEditModalClose = () => setEditModal(false);

    const handleView = (startYear: string, endYear: string) => {
        setStartYear(startYear);
        setEndYear(endYear);
        handleViewModalOpen();
    };
    const handleEdit = (startYear: string, endYear: string) => {
        setStartYear(startYear);
        setEndYear(endYear);
        handleEditModalOpen();
    };
    interface fiscalYearType {
        id: number;
        agency: string;
        startYear: string;
        endYear: string;
    }
    return (
        <>
            {data?.data.length === 0 && (
                <Button variant="contained" sx={{ marginBottom: '20px' }} onClick={() => handleCreateModalOpen()}>
                    Add Data
                </Button>
            )}
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">SL</TableCell>
                            <TableCell align="left">Fiscal Year</TableCell>
                            <TableCell align="left">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.data.map((item: fiscalYearType, index: number) => (
                            <TableRow key={item.id}>
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left">
                                    {item.startYear}-{item.endYear}
                                </TableCell>
                                <TableCell align="left">
                                    <VisibilityIcon sx={{ cursor: 'pointer' }} onClick={() => handleView(item.startYear, item.endYear)} />
                                    <EditIcon onClick={() => handleEdit(item.startYear, item.endYear)} sx={{ cursor: 'pointer' }} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {viewModal && (
                <DamDetailsByFiscalYear
                    open={viewModal}
                    handleClose={handleViewModalClose}
                    agency="4"
                    startYear={startYear}
                    endYear={endYear}
                />
            )}
            {editModal && (
                <DamDetailsEdit open={editModal} handleClose={handleEditModalClose} agency="4" startYear={startYear} endYear={endYear} />
            )}
            <Dam pOpen={createModal} pHandleClose={handleCreateModalClose} />
        </>
    );
};

export default DamMain;
