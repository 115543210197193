import useValidationLIst from 'hooks/consume_api/query/useValidationLIst'
import React, { useState } from 'react'
import Loader from 'ui-component/Loader';
import { Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ValidationListBySubActivity from './ValidationListBySubActivity';
import useValidationBulkSubmission from 'hooks/consume_api/mutation/useValidationBulkSubmission';
interface IValidation {
    sub_activity_name: string,
    sub_activity_type: string,
    total: string
}
const ValidationMain: React.FC = () => {
    const { data, isFetching } = useValidationLIst();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [subActivity, setSubActivity] = useState<string>("");

    const { mutate: bulkSubmissionMutate } = useValidationBulkSubmission();

    const handleModalClose = () => setOpenModal(false);
    const handleModalOpen = () => setOpenModal(true);

    const handleView = (sub_activity_type: string) => {
        setSubActivity(sub_activity_type);
        handleModalOpen();
    }

    return (
        <>
            {isFetching && <Loader />}
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">#</TableCell>
                            <TableCell align="left">Sub activity name</TableCell>
                            <TableCell align="left">Count</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {data?.data && data?.data.map((item: IValidation, index: number) => (
                            <TableRow key={index}>
                                <TableCell align="left">{index}</TableCell>
                                <TableCell align="left">{item.sub_activity_name}</TableCell>
                                <TableCell align="left">{item.total}</TableCell>
                                <TableCell align="left">{item.total !== "0" && <Stack direction="row">
                                    <Button onClick={() => {
                                        bulkSubmissionMutate({ sub_activity: item.sub_activity_type })
                                    }} sx={{ color: "green" }}>Accept all</Button>
                                    <Button onClick={() => handleView(item.sub_activity_type)} sx={{ color: "blue" }}>View</Button>
                                </Stack>}</TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>

            {openModal &&
                <ValidationListBySubActivity
                    open={openModal}
                    handleClose={handleModalClose}
                    subActivityType={subActivity} />
            }

        </>
    )
}

export default ValidationMain