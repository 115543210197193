import React, { useEffect, useState } from 'react';

const useDebounce = (value: string, delay: number) => {
    const [debounceValue, setDebounceValue] = useState<string>('');

    useEffect(() => {
        const id = setTimeout(() => {
            setDebounceValue(value);
        }, delay);

        return () => {
            clearTimeout(id);
        };
    }, [value, delay]);

    return debounceValue;
};

export default useDebounce;
