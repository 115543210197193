import { TextField } from '@mui/material'
import React from 'react'

interface props {
    startYear: string,
    endYear: string,
    id: string | number,
    handleTarget(startYear: string, endYear: string, id: string | number, target: string): void
}
const BadcTarget: React.FC<props> = ({ startYear, endYear, id, handleTarget }) => {
    return (
        <TextField onChange={(e) => {
            handleTarget(startYear, endYear, id, e.target.value)
        }} />
    )
}

export default BadcTarget