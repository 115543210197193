import { useQuery } from '@tanstack/react-query';
import { subActivityKey } from '../../../react_query/query-keys';
import { subActivity } from '../../../services/reactQueryservices';

const useSubActivityList = () => {
    const { data, isFetching, error } = useQuery({
        queryKey: [subActivityKey],
        queryFn: () => subActivity()
    })

    return {
        data,
        isFetching,
        error
    }
}

export default useSubActivityList