import { useEffect, useState } from 'react';
import { ChartData } from '../../../types'; // Update the import path based on your actual types
import { useQuery } from '@tanstack/react-query';
import { chartDataKeys } from '../../../react_query/query-keys'; // Update the import path based on your actual query keys
import { getChartData } from '../../../services/reactQueryservices'; // Update the import path based on your actual service
import { DEFAULT_STALE_TIME } from '../../../utils/Constants';

const useGetChartData = (chartOption: string, dependencies: boolean = true) => {
    const [initiateFetchingChartData, setInitiateFetchingChartData] = useState(true);

    const [chartData, setChartData] = useState<ChartData[]>([]);

    const result = useQuery<ChartData[], Error>({
        queryKey: chartDataKeys.list(chartOption), // Update the query key based on your actual implementation
        queryFn: () => getChartData({ chartOption: chartOption }), // Update the function based on your actual service
        staleTime: DEFAULT_STALE_TIME,
        enabled: initiateFetchingChartData && dependencies
    });

    useEffect(() => {
        if (result.data && !result.isError) {
            setChartData(result.data);
        }
    }, [result.data]);

    return {
        chartData,
        isChartDataFetching: result.isFetching,
        isChartDataFetched: result.isFetched,
        isChartDataError: result.isError,
        chartDataError: result.error,
        isChartDataSuccess: result.isSuccess,
        ...result,
        setInitiateFetchingChartData
    };
};

export default useGetChartData;
