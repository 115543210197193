import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import useMemberInstanceDelete from 'hooks/consume_api/mutation/useMemberInstanceDelete';

interface props {
    id: string | number;
    open: boolean;
    onClose: () => void;
    handleReload: () => void;
}
const MemberDelete: React.FC<props> = ({ id, open, onClose, handleReload }) => {
    const { mutate, isSuccess } = useMemberInstanceDelete();
    const handleDelete = () => {
        mutate(id.toString());
        onClose();
    }
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <Typography>
                    Are you sure you want to delete ?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDelete} color="secondary" variant="contained">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MemberDelete