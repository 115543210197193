import { useQuery } from '@tanstack/react-query';
import { subActivityFao } from '../../../react_query/query-keys';
import { subActivityCountFao } from '../../../services/reactQueryservices';


const useSubQueryCountFao = (subActivity: string, startYear: string, endYear: string) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [subActivityFao, subActivity, startYear, endYear],
        queryFn: () => subActivityCountFao(subActivity, startYear, endYear),
        enabled: true && !!subActivity
    })
    return {
        data,
        isFetching,
        error
    }
}

export default useSubQueryCountFao