import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    TableContainer,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';
import { Box } from '@mui/system';
import useGetWorkPlanListByYearAndAgency from 'hooks/consume_api/query/useGetWorkPlanListByYearAndAgency';
import React from 'react';
import Loader from 'ui-component/Loader';
interface props {
    open: boolean;
    handleClose: () => void;
    startYear: string;
    endYear: string;
    agency: string;
}
interface JsonData {
    id: string;
    projectName: string;
    unit: string;
    quantity: string;
    progressDate: string;
    cumulativeAchievement: string;
}

interface FiscalYearData {
    id: number;
    agency: string;
    subActivity: string;
    yearStart: string;
    yearEnd: string;
    target: string;
    achievement: string;
    parcent: string;
}

interface WorkPlanData {
    id: number;
    agency: string;
    subActivity: string;
    json: JsonData;
    fiscalYearData: FiscalYearData;
}

const DamDetailsByFiscalYear: React.FC<props> = ({ open, handleClose, startYear, endYear, agency }) => {
    const { data, isFetching } = useGetWorkPlanListByYearAndAgency(agency, startYear, endYear, open);
    return (
        <>
            {isFetching && <Loader />}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">{`Work plan data by fiscal year`}</DialogTitle>
                <DialogContent>
                    <TableContainer sx={{ marginTop: '10px' }} component={Paper}>
                        <Box sx={{ height: 400, overflowY: 'auto' }}>
                            <Table stickyHeader sx={{ width: '100%' }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">#</TableCell>
                                        <TableCell align="left">Sub-Activity</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                            Project Name
                                        </TableCell>
                                        <TableCell align="left">Unit</TableCell>
                                        <TableCell align="left">Quantity</TableCell>

                                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                            {startYear} - {endYear} (Target)
                                        </TableCell>
                                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                            {startYear} - {endYear} (Achievement)
                                        </TableCell>
                                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                            {startYear} - {endYear} (%)
                                        </TableCell>

                                        <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                            Cumulative Achievement
                                        </TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                            Progress upto date
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data &&
                                        data.data.map((item: WorkPlanData, index: number) => (
                                            <TableRow>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {item.subActivity}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {item.agency}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {item.json.unit}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {item.json.quantity}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {item.fiscalYearData.target}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {item.fiscalYearData.achievement}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {item.fiscalYearData.parcent}%
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {item.json.cumulativeAchievement}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {item.json.progressDate}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DamDetailsByFiscalYear