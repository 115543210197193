import React from 'react';
import { disAggregationListKey } from 'react_query/query-keys';
import { getDisaggregationlist } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';

const useDisaggregationlist = (logFrameId: string) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [disAggregationListKey, logFrameId],
        queryFn: () => getDisaggregationlist(logFrameId),
        enabled: !!logFrameId
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };
};

export default useDisaggregationlist;
