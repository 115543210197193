import React from 'react';
import { dashboardInfrastructure } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';
import { dashboardInfrastructureKey } from '../../../react_query/query-keys';

const useDashboardInfrastructure = (project: string, agency: string, division: string, district: string, upazila: string) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [dashboardInfrastructureKey, division, district, upazila, project, agency],
        queryFn: () => dashboardInfrastructure(project, agency, division, district, upazila)
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };
}

export default useDashboardInfrastructure