import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    TableContainer,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField
} from '@mui/material';
import { Box } from '@mui/system';
import useGetWorkPlanListByYearAndAgency from 'hooks/consume_api/query/useGetWorkPlanListByYearAndAgency';
import React, { useState } from 'react';
import Loader from 'ui-component/Loader';
import ValueEdit from '../../Reusable/ValueEdit';
import useWorkPlanUpdate from 'hooks/consume_api/mutation/useWorkPlanUpdate';
interface props {
    open: boolean;
    handleClose: () => void;
    startYear: string;
    endYear: string;
    agency: string;
}
interface JsonData {
    id: string;
    projectName: string;
    unit: string;
    quantity: string;
    progressDate: string;
    cumulativeAchievement: string;
}

interface FiscalYearData {
    id: number;
    agency: string;
    subActivity: string;
    yearStart: string;
    yearEnd: string;
    target: string;
    achievement: string;
    parcent: string;
}

interface WorkPlanData {
    id: number;
    agency: string;
    subActivity: string;
    json: JsonData;
    fiscalYearData: FiscalYearData;
}

const BariDetailsEdit: React.FC<props> = ({ open, handleClose, startYear, endYear, agency }) => {
    const { data, isFetching } = useGetWorkPlanListByYearAndAgency(agency, startYear, endYear, open);
    const { mutate } = useWorkPlanUpdate();

    const [target, setTarget] = useState<
        {
            id: number | string;
            targetValue: string;
        }[]
    >([]);
    const [quantity, setQuantity] = useState<
        {
            id: number;
            quantityValue: string;
        }[]
    >([]);

    const [cumulitive, setCumulitive] = useState<
        {
            id: number;
            cumulitiveValue: string;
        }[]
    >([]);

    const [progress, setProgress] = useState<
        {
            id: number;
            progressValue: string;
        }[]
    >([]);

    const handleTarget = (id: number | string, targetValue: string) => {
        setTarget((prev) => {
            const existingIndex = prev.findIndex((item) => item.id === id);
            if (existingIndex !== -1) {
                prev[existingIndex] = { id, targetValue };
            } else {
                prev.push({ id, targetValue });
            }
            return [...prev];
        });
    };

    const handleQuntity = (id: number, quantityValue: string) => {
        setQuantity((prev) => {
            const existingIndex = prev.findIndex((item) => item.id === id);
            if (existingIndex !== -1) {
                prev[existingIndex] = { id, quantityValue };
            } else {
                prev.push({ id, quantityValue });
            }
            return [...prev];
        });
    };

    const handleCumulitive = (id: number, cumulitiveValue: string) => {
        setCumulitive((prev) => {
            const existingIndex = prev.findIndex((item) => item.id === id);
            if (existingIndex !== -1) {
                prev[existingIndex] = { id, cumulitiveValue };
            } else {
                prev.push({ id, cumulitiveValue });
            }
            return [...prev];
        });
    };

    const handleProgress = (id: number, progressValue: string) => {
        setProgress((prev) => {
            const existingIndex = prev.findIndex((item) => item.id === id);
            if (existingIndex !== -1) {
                prev[existingIndex] = { id, progressValue };
            } else {
                prev.push({ id, progressValue });
            }
            return [...prev];
        });
    };
    const handleSave = () => {
        const data = {
            data: {
                quantity: quantity,
                cumulititive: cumulitive,
                progress: progress,
                target: target
            }

        }
        mutate(data);
        handleClose();
    }
    return (
        <>
            {isFetching && <Loader />}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">{`Work plan data by fiscal year`}</DialogTitle>
                <DialogContent>
                    <TableContainer sx={{ marginTop: '10px' }} component={Paper}>
                        <Box sx={{ height: 400, overflowY: 'auto' }}>
                            <Table stickyHeader sx={{ width: '100%' }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">#</TableCell>
                                        <TableCell align="left">Sub-Activity</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                            Project Name
                                        </TableCell>
                                        <TableCell align="left">Unit</TableCell>
                                        <TableCell align="left">Quantity</TableCell>

                                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                            {startYear} - {endYear} (Target)
                                        </TableCell>
                                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                            {startYear} - {endYear} (Achievement)
                                        </TableCell>
                                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                            {startYear} - {endYear} (%)
                                        </TableCell>

                                        <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                            Cumulative Achievement
                                        </TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                            Progress upto date
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isFetching === false && data && data.data &&
                                        data.data.map((item: WorkPlanData, index: number) => (

                                            <TableRow key={index}>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {item.subActivity}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {item.agency}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    {item.json.unit}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    <ValueEdit id={item.id} value={item.json.quantity} handleValue={handleQuntity} />
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    <ValueEdit
                                                        id={item.fiscalYearData.id}
                                                        value={item.fiscalYearData.target}
                                                        handleValue={handleTarget}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    <TextField value={item.fiscalYearData.achievement} />
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    <TextField value={item.fiscalYearData.parcent} />
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    <ValueEdit
                                                        id={item.id}
                                                        value={item.json.cumulativeAchievement}
                                                        handleValue={handleCumulitive}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                                                    <ValueEdit
                                                        id={item.id}
                                                        value={item.json.progressDate}
                                                        handleValue={handleProgress}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </TableContainer>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={handleSave}>Save</Button>
                    <Button onClick={() => handleClose()}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default BariDetailsEdit