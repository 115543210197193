import { TextField } from '@mui/material';
import React from 'react';
interface props {
    id: string | number,
    handleProgressUptoDate(id: string | number, progressUptoDate: string): void
}

const GainProgressUptoDate: React.FC<props> = ({ id, handleProgressUptoDate }) => {

    return (
        <TextField
            onChange={(e) => {
                handleProgressUptoDate(id, e.target.value);
            }}
            id="outlined-basic"
            variant="outlined" />
    )

}

export default GainProgressUptoDate