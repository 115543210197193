import useSnackbar from 'hooks/useSnackbar';
import { validationListKey } from 'react_query/query-keys';
import { validationBulkSubmission } from 'services/reactQueryservices';
import { client } from 'react_query/query-client-configuration';
import { useMutation } from '@tanstack/react-query';

const useValidationBulkSubmission = () => {
    const snackbar = useSnackbar();
    const { mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: validationBulkSubmission,
        onSettled: (data, error) => {
            if (error) {
                snackbar('Something went wrong', 'error');
            } else {
                client.invalidateQueries({ queryKey: [validationListKey] });
                snackbar('Operation Successful');
            }
        }
    });
    return {
        mutate,
        error,
        isSuccess,
        isError,
        isLoading
    };
}

export default useValidationBulkSubmission