import { getProjectNameFromLogframeId } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';
import { projectName } from '../../../react_query/query-keys';


const useGetProjectNameFromLogFrameId = (logframeId: string) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [projectName, logframeId],
        queryFn: () => getProjectNameFromLogframeId(logframeId),
        enabled: !!logframeId
    });



    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };
}

export default useGetProjectNameFromLogFrameId