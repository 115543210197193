import { useQuery } from '@tanstack/react-query';
import { locationList } from '../../../react_query/query-keys';
import { location } from '../../../services/reactQueryservices';

const useGetLocationList = (parent: string) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [locationList, parent],
        queryFn: () => location(parent),
        enabled: true && !!parent
    })
    return {
        data,
        isFetching,
        error
    }
}

export default useGetLocationList;