import { useMutation } from '@tanstack/react-query';
import React from 'react'
import { groupMemberDelete } from 'services/reactQueryservices';
import useSnackbar from "../../useSnackbar";

const useGroupDelete = () => {
    const snackbar = useSnackbar()
    const { mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: groupMemberDelete,
        onSettled: (data, error) => {
            if (error) {
            } else {
                snackbar('successfully deleted');
            }
        }
    });
    return {
        mutate,
        error,
        isSuccess,
        isError,
        isLoading
    };
}

export default useGroupDelete;