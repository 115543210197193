import { useQuery } from '@tanstack/react-query';
import { subActivityDae } from '../../../react_query/query-keys';
import { subActivityCountDae } from '../../../services/reactQueryservices';

const useSubActivitiyCountDae = (subActivity: string, startYear: string, endYear: string) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [subActivityDae, subActivity, startYear, endYear],
        queryFn: () => subActivityCountDae(subActivity, startYear, endYear),
        enabled: true && !!subActivity,
        staleTime: 300
    });

    return {
        data,
        isFetching,
        error
    };
};

export default useSubActivitiyCountDae;
