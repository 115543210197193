import { baselineDisaggregationCreate } from '../../../services/reactQueryservices'; // Replace with your actual service function for approving inputs
import { useMutation } from '@tanstack/react-query';
import useSnackbar from '../../useSnackbar'; // Replace with your actual snackbar hook

const useBaselineDisaggregationCreate = () => {
    const snackbar = useSnackbar();
    const { mutateAsync, mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: baselineDisaggregationCreate,
        onSettled: (data, error) => {
            if (error) {
                snackbar('Failed to update status', 'error');
            } else {
                snackbar('Submission successful');
            }
        }
    });

    return {
        mutate
    }
}

export default useBaselineDisaggregationCreate