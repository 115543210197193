import useSubActivityCountDam from 'hooks/consume_api/query/useSubActivityCountDam';
import React, { useEffect } from 'react';
interface props {
    valueText: string;
    startYear: string;
    endYear: string;
    handleAchievement(id: string | number, value: string | number, startYear: string, endYear: string): void;
}

const DamAchievement: React.FC<props> = ({ valueText, startYear, endYear, handleAchievement }) => {
    const { data } = useSubActivityCountDam(valueText, startYear, endYear);
    useEffect(() => {
        if (data) {
            handleAchievement(valueText, data.data.total, startYear, endYear);
        }
    }, [data]);

    return <div>{data?.data.total}</div>;
};

export default DamAchievement;
