import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
interface popUp {
    open: boolean,
    handleClose: () => void,
    yearAssign: (startYear: string, endYear: string) => void
}
const fiscalYears = [];
for (let year = 2015; year < 2030; year++) {
    fiscalYears.push({
        label: `${year}-${(year + 1).toString().slice(-2)}`,
        value: {
            startYear: year.toString(),
            endYear: (year + 1).toString()
        }
    });
}

const yearData = fiscalYears;
const FiscalYearPopUp: React.FC<popUp> = ({ open, handleClose, yearAssign }) => {
    const [selectedStartYear, setSelectedStartYear] = useState<string | undefined>("");
    const [selectedEndYear, setSelectedEndYear] = useState<string | undefined>("");

    const handleSave = () => {
        if (selectedStartYear && selectedEndYear) {
            yearAssign(selectedStartYear, selectedEndYear);
            handleClose();
        }
    }

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                {"Select fiscal year"}
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={4}>
                        <Typography >Start Fiscal Year</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={yearData}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setSelectedStartYear(newValue?.value.startYear);
                                setSelectedEndYear(newValue?.value.endYear);
                            }}
                            renderInput={(params) => <TextField {...params} label="Select Year" variant="outlined" />}

                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>

    )
}

export default FiscalYearPopUp