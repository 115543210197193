import useSubActivitiyCountDae from 'hooks/consume_api/query/useSubActivitiyCountDae';
import useSubActivityCountBadc from 'hooks/consume_api/query/useSubActivityCountBadc';
import React, { useEffect } from 'react';

interface props {
    valueText: string;
    startYear: string;
    endYear: string;
    handleAchievement(id: string | number, value: string | number, startYear: string, endYear: string): void;
}

const DaeAchieveemnt: React.FC<props> = ({ valueText, startYear, endYear, handleAchievement }) => {
    const { data, isFetching } = useSubActivitiyCountDae(valueText, startYear, endYear);
    useEffect(() => {
        if (data) {
            handleAchievement(valueText, data.data.total, startYear, endYear);
        }
    }, [data]);
    return (
        <div>
            {isFetching && 'loading..'}
            {data?.data.total}
        </div>
    );
};

export default DaeAchieveemnt;
