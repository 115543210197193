import { TextField } from '@mui/material';
import React from 'react';
interface props {
    id: string | number;
    handleUnit(id: string | number, unit: string): void;
}

const DamQuantity: React.FC<props> = ({ id, handleUnit }) => {
    return (
        <TextField
            onChange={(e) => {
                handleUnit(id, e.target.value);
            }}
            id="outlined-basic"
            variant="outlined"
        />
    );
};

export default DamQuantity;
